import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Typography,useMediaQuery} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TextField from '@mui/material/TextField';
import JumboSearch from "@jumbo/components/JumboSearch";
// import ClassStudentsList from "./ClassStudentsList";
import ListRow from "../../../components/ListRow";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const DepartmentForm = () => {

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const navigate = useNavigate();

    const Swal = useSwalWrapper();
    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });

        //  Redireciona se mensagem de retorno for de sucesso
        if(variant == 'success' && (!departmentImage || (departmentImage && type))){
            setTimeout(function(){
                navigate("/app/listar-departamentos")
            }, 2000)
        }
    };

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        area_id: "",
        nome: ""
    }

    const validationSchema = yup.object().shape({
        area_id: yup
            .string()
            .required('Preenchimento obrigatório'),
        nome: yup
            .string()
            .required('Preenchimento obrigatório'),
    }, []);

    const { id } = useParams()
    const [areas, setAreas] = useState([]);
    const [departments, setDepartments] = useState(initialValues);
    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)

    const [departmentImage, setDepartmentImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);


    const [addStudents, setAddStudents] = useState(null);
    const [classStudents, setClassStudents] = useState([]);

    const { hasPermission } = useContext(PermissionContext)

    const canCreate = hasPermission('Departamentos', 'criar');
    const canEdit = hasPermission('Departamentos', 'editar');
    const isDisabled = id ? !canEdit : !canCreate

    useEffect(() => {
        if(searchTerm == undefined || searchTerm == ''){
            setAddStudents(null);
            return;
        } 

        const delayDebounceFn = setTimeout(() => {
          searchStudent(searchTerm)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

      const searchStudent = useCallback(async (e) => {
        try {
             ApiService.get(`/pessoa`, {params: {search: e, turma_id: id}})
            .then((response) => {
                setAddStudents(response.data)
                // toast('success', 'Aluno adicionado com sucesso');
            })
            .catch((error) => {
                // toast('error', 'Ocorreu um erro');

              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

    const getAreas = useCallback(async () => {
        try {
            ApiService.get(`/areas`)
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const updateStudentList = useCallback(async (data) => {
        setClassStudents([...data]);
        setAddStudents(null);
    }, []);
    
    const getDepartmentById = useCallback(async () => {
        try {
            ApiService.get(`/departamentos/${id}`)
            .then((response) => {
              setDepartments(response.data);

            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getDepartmentById();
        getAreas();
    }, []);

    const handleChangeImage = (e) => {
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        setDepartmentImage(e.target.files[0])
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        var departmentID = id;
        let data = new FormData()
        data.append("imagem_departamento", departmentImage)

        if(id){
            ApiService.put(`/departamentos/${id}`, { values })
               .then(() => {
                    toast('success', 'Atualizado com sucesso')
                })
               .catch((error) => {
                    toast('error', 'Ocorreu um erro')
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
               })

               if(departmentImage) {
                ApiService.put(`/departamentos/atualizar-imagem/${departmentID}`, data)
                  .then(() => {
                    toast('success', 'Imagem atualizada com sucesso', true)
                  })
                  .catch((error) => {
                    toast('error', 'Ocorreu um erro ao atualizar imagem', true)
    
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                      } else if (error.request) {
                        console.log(error.request);
                      } else {
                        console.log('error', error.message);
                      }
                  })
                
                }
        } else {
            await ApiService.post('/departamentos', { values })
                .then((response) => {
                departmentID = response.data.departamento_id

                toast('success', 'Criado com sucesso')
                })
                .catch((error) => {
                toast('error', 'Ocorreu um erro')
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })

                if(departmentImage) {
                    ApiService.put(`/departamentos/atualizar-imagem/${departmentID}`, data)
                      .then(() => {
                        toast('success', 'Criado com sucesso', true)
                      })
                      .catch((error) => {
                        toast('error', 'Ocorreu um erro ao atualizar imagem', true)
        
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                          } else if (error.request) {
                            console.log(error.request);
                          } else {
                            console.log('error', error.message);
                          }
                      })
                    
                }
        }

        

        setSubmitting(false);
    } 

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Departamentos"}
                subtitle={id ? "Editar Departamento" : "Cadastro de Departamentos"}
                titleUrl={"/app/listar-departamentos"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
                
                        <Formik
                            initialValues={departments}
                            validationSchema={validationSchema}
                            enableReinitialize
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={handleSubmit}
                        >
                            {({values, isSubmitting, handleChange}) => (
                                <Form style={{width: '100%'}} noValidate autoComplete='off'>
                                    <Grid container spacing={3} alignContent={'center'}>
                                        <Grid item lg={3} xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {previewImage && 
                                                <img src={previewImage} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                            }
                                            {values.imagem_departamento && !previewImage && 
                                                <img src={`${process.env.REACT_APP_API_KEY}/images/${values.imagem_departamento}`} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                            }
                                            <Button 
                                                variant="contained" 
                                                component="label" 
                                                color="info" sx={{ height: '53.13px',fontSize: {xs: '0.7rem'} }}
                                                disabled={isDisabled}
                                            >
                                                Adicionar foto
                                                <input type="file" hidden onChange={handleChangeImage}/>
                                            </Button>
                                            
                                        </Grid>

                                        <Grid item xs={3} >
                                            <JumboTextField
                                                fullWidth
                                                name="nome"
                                                label="Nome"
                                                value={updateInputValue(departments, values, 'nome')}
                                                disabled={isDisabled}
                                            />
                                        </Grid>

                                        <Grid item xs={6} >
                                            <FormControl fullWidth>
                                                <InputLabel id="area_id">Área</InputLabel>
                                                <JumboSelectField
                                                    labelId="area_id"
                                                    id="area_id"
                                                    name="area_id"
                                                    value={updateInputValue(departments, values, 'area_id')}
                                                    label="Área"
                                                    disabled={isDisabled}
                                                >
                                                    {areas.length > 0 && areas.map((x) => 
                                                        <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                                                    )}
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sx={{textAlign: 'center', mt: '1rem'}}> 
                                            <LoadingButton
                                                color="success"
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                                loading={isSubmitting}
                                                disabled={isDisabled}
                                            >
                                                {id ? 'Atualizar' : 'Cadastrar'}
                                            </LoadingButton>

                                        </Grid>

                                    </Grid>
                                </Form>
                            )}
                        </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default DepartmentForm