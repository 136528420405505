import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { PermissionContext } from 'app/contexts/PermissionContext';

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const ProfileUserListItem = ({ item ,setProfileUser, profileUser, courses }) => {
  
    const { hasPermission } = useContext(PermissionContext);

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const modalAlert = (usuario_perfil_id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                removeStudentFromClass(usuario_perfil_id);
            }
        });
    };

    function removeById(arr, id) {
        console.log('arr', arr)
        console.log('id', id)
        const objWithIdIndex = arr.findIndex((obj) => obj.usuario_perfil_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
      }

      const removeStudentFromClass = useCallback(async (usuario_perfil_id) => {
        try {
            ApiService.delete(`/perfil-usuario/${usuario_perfil_id}`,)
            .then((response) => {
                toast('success', 'Removido com sucesso');

                const updatedStudentsArray = removeById(profileUser, usuario_perfil_id)
                setProfileUser(updatedStudentsArray);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, [profileUser, setProfileUser]);


    return (
         <Card sx={{mb: 1}}>
            <Accordion square sx={{borderRadius: 2}} >
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                   
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{flexShrink: 0, px: 1}}>
                        <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`} variant="rounded"/>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '50%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Nome
                        </Typography>
                        <Typography variant={"body1"}>{item.nome}</Typography>
                    </Div>
                    <Item sx={{ml: 'auto', display: {xs: 'none', sm: 'block'}}}>
                        <Button 
                            sx={{minWidth: 1}} 
                            disableElevation 
                            variant={"contained"} 
                            size={"small"} 
                            color={"secondary"}
                            disabled={!hasPermission('Perfis', 'deletar')}
                            onClick={() => modalAlert(item.usuario_perfil_id)}
                        >
                            <DeleteIcon />
                        </Button>
                    </Item> 
    
                </AccordionSummary>
            </Accordion>
            
        </Card>
    );
};
/* Todo item prop define */
export default ProfileUserListItem;
