import React, { useState, useCallback, useEffect, Fragment, useContext } from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Paper, Stack, useMediaQuery, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ApiService } from "app/servicesTwo/ApiService";
import ProfileItem from "./ProfileItem";
import JumboSearch from "@jumbo/components/JumboSearch";
import { Link } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";

const ProfileList = () => {
  const { theme } = useJumboTheme();

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const [profiles, setProfiles] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  useEffect(() => {
    if (searchTerm == undefined) return;

    if (searchTerm == "") {
      setLoading(true);
      searchProfile(searchTerm);
      setLoading(false);
    }

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      searchProfile(searchTerm);
      setLoading(false);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getProfiles = useCallback(async () => {
    try {
      ApiService.get("/perfil")
        .then((response) => {
          setProfiles(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchProfile = useCallback(async (e) => {
    try {
      ApiService.get("/perfil", { params: { search: e } })
        .then((response) => {
          setProfiles(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getProfiles();
  }, []);

  useEffect(() => {
    console.log("atualizou profiles", profiles);
  }, [setProfiles, profiles]);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Perfis"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "40px" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Link to="/app/novo-perfil" style={{ textDecoration: "none", pointerEvents: !hasPermission('Perfis', 'criar') ? 'none' : '' }}>
            <LoadingButton
              color="success"
              type="submit"
              variant="contained"
              size="large"
              disabled={!hasPermission('Perfis', 'criar')}
              sx={{
                maxWidth: { md: "200px", mt: "1rem" },
                marginBottom: "2rem",
              }}
            >
              Novo Perfil
            </LoadingButton>
          </Link>
        </Grid>
        <Grid item xs={1} sx={{ textAlign: "center" }}>
          <JumboSearch
            onChange={(e) => setSearchTerm(e)}
            placeholder="Busque por nome do perfil."
            sx={{
              width: "440px",
              marginBottom: "1rem",
            }}
          />
        </Grid>

        <Fragment>
          {profiles &&
            profiles.map((user, key) => (
              <ProfileItem
                item={user}
                setProfiles={(data) => setProfiles([...data])}
                profiles={profiles}
                key={key}
              />
            ))}
          {profiles && profiles.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default ProfileList;
