import React, { useCallback, useState, useContext, useEffect } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, AccordionDetails, Card, FormControl, Grid, InputLabel, MenuItem, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import {TIME} from "../../../utils/constants/time";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";
import { PermissionContext } from 'app/contexts/PermissionContext';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { LinkStyle } from 'app/pages/app/volunteers/VolunteerItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation } from 'react-router-dom';

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const VolunteerHistoryItem = ({ item , handleChange, handleClose}) => {
    const [expanded, setExpanded] = useState(false);
    const [areas, setAreas] = useState(null);
    const [departments, setDepartments] = useState(null);
    const { hasPermission } = useContext(PermissionContext)
    const location = useLocation();

    const toggleAccordion = () => setExpanded(!expanded);

    const getAreas = useCallback(async () => {
        try {
            ApiService.get('/areas')
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get('/departamentos')
            .then((response) => {
              setDepartments(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        getAreas();
        getDepartments();
    }, []);

    const verifyAreasandDepartments = () => {
        if(item.Areas == null) {
            if(!departments?.some(y => y.departamento_id === item.departamento_id)) {
                return<Typography variant={"body1"}>Departamento excluído</Typography>
            }else {
                return <Typography variant={"body1"}>{item.Departamento?.nome}</Typography>
            }
        }
        if(item.Departamento == null) {
            if(!areas?.some(y => y.area_id === item.area_id)) {
                return <Typography variant={"body1"}>Área excluída</Typography>
            } else {
                return <Typography variant={"body1"}>{item.Areas?.nome}</Typography>
            }
        }
    };


    return (
        <Card sx={{mb: 2}} style={{ display: 'flex', flexDirection: 'row' }}>
            
            <Accordion expanded={expanded} onChange={handleChange} square sx={{borderRadius: 2, width: '100%'}}>
                <AccordionSummary
                    expandIcon={ item.Horario_voluntariado || item.Trabalho_Voluntario_Fora_Feesp
                        ? 
                        <ExpandMoreIcon
                            onClick={toggleAccordion}
                        /> 
                        : 
                        null
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Voluntário
                        </Typography>
                        <Typography variant={"body1"}> 
                            {verifyAreasandDepartments()}
                        </Typography>
                    </Div>
                   
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Local
                        </Typography>
                        <Typography variant={"body1"}>{item.local}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Realizou vivência prática?
                        </Typography>
                        <Typography variant={"body1"}>
                            {item.teve_vivencia_pratica ? 'Sim' : 'Não'}
                        </Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Status
                        </Typography>
                        <Typography variant={"body1"}>{item.status}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '20%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Ficha
                        </Typography>
                        <Typography variant={"body1"}>{item.ficha ? 'Sim' : 'Não'}</Typography>
                    </Div>
                    
                </AccordionSummary>
               
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <JumboTextField
                                fullWidth
                                name="data_inicio"
                                type="date"
                                label="Data de inicio"
                                value={item.data_inicio}
                                sx={{ background: '#ffffff'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                            />
                        </Grid> 
                        <Grid item xs={6}>
                            <JumboTextField
                                fullWidth
                                name="data_fim"
                                type="date"
                                label="Data Final"
                                value={item.data_fim}
                                sx={{ background: '#ffffff'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                            />
                        </Grid> 

                        <Grid item xs={6} >
                            <JumboTextField
                                fullWidth
                                name="atividade"
                                label="Atividade"
                                value={item.atividade}
                                disabled={true}
                            />
                        </Grid> 
                        <Grid item lg={6} xs={2} >
                            <Div sx={{ display: 'flex', flexDirection: 'row', }}>
                                { item.ficha &&
                                    <>
                                        <embed 
                                            src={`${process.env.REACT_APP_API_KEY}/images/${item.ficha}`} 
                                            type="application/pdf" 
                                            style={{ 
                                                width: 50, 
                                                height: 50, 
                                                borderRadius: 50, 
                                                marginRight: 10,
                                                overflow: 'hidden'
                                            }}
                                        />
                                        <Button href={`${process.env.REACT_APP_API_KEY}/images/${item.ficha}`} target="_blank">
                                            Abrir arquivo
                                        </Button>
                                    </> 
                                }
                            </Div>
                        </Grid>
                    </Grid>

                </AccordionDetails>
                    
            </Accordion>
            <Item sx={{ml: 'auto', display: {xs: 'none', sm: 'flex'}, alignSelf: 'center', flexDirection: 'row', gap: 2}} disableElevation>
                <LinkStyle 
                    to={`/app/listar-voluntariado-resume/${item.voluntario_id}`}
                    state={{ backUrl: location.pathname }}
                    disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                   
                >
                    <ArrowForwardIosIcon/>
                    
                </LinkStyle>
            </Item>
        </Card>
    );
};
/* Todo item prop define */
export default VolunteerHistoryItem;
