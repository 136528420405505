import React, {useCallback, useState} from 'react';
// import {connections} from "./data";
import {List} from "@mui/material";
import ListItem from "./ListItem";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const ListRow = ({ data , setAddStudents, updateStudentList, classStudents, setSearchTerm}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const [itemsList, setItemsList] = useState(data);
    const includeStudentToClass = useCallback((record) => {
        try {
            ApiService.post(`/turmas/${id}/aluno/${record.pessoa_id}`)
            .then((response) => {
                let newArray = classStudents;
                record.curso_historico_id = response.data.curso_historico_id;
                record.status = response.data.status;
                newArray.push(record)
                updateStudentList(newArray);
                toast('success', 'Aluno adicionado com sucesso');
                setSearchTerm('')
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }

    }, [itemsList]);

    return (
            <List disablePadding>
                {
                    itemsList.map((item, index) => (
                        <ListItem item={item} key={index} includeStudentToClass={includeStudentToClass}/>
                    ))
                }
            </List>
    );
};

export default ListRow;
