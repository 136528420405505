import React, { useState, useCallback, useEffect, Fragment, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, Stack,Switch,TextField,Typography,useMediaQuery} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import { CheckBox, CollectionsBookmarkOutlined } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { PermissionContext } from 'app/contexts/PermissionContext';

const ModalChangeStatus = ({ data, handleClose, classStudents, setClassStudents }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        status: data.status ?? null,
        observacao: data.observacao ?? null,
        motivo_reprovacao: data.motivo_reprovacao ?? null
    }

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const [classData, setClassData] = useState([]);

    const { hasPermission } = useContext(PermissionContext);

    function updateById(arr, id, data) {
    const objIndex = arr.findIndex((obj => obj.curso_historico_id === id));

    arr[objIndex].status = data.status
    arr[objIndex].observacao = data.observacao
    arr[objIndex].motivo_reprovacao = data.motivo_reprovacao
    
    return arr;
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        const curso_historico_id = data.curso_historico_id;

        ApiService.put(`/turmas/atualizar-status/${curso_historico_id}`, { values } )
            .then(() => {
                const newArr = updateById(classStudents, curso_historico_id, values);
                setClassStudents(newArr)
                
                toast('success', 'Status atualizado com sucesso')
                handleClose();
            })
            .catch((error) => {
                toast('error', 'Ocorreu um erro')

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
    }

  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>Atualização de Status</Typography>
                <Fragment>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={handleSubmit}
                >
                    {({values, isSubmitting, handleChange}) => (
                        <Form style={{width: '100%'}} noValidate autoComplete='off'>
                            <Grid container spacing={3} alignContent={'center'}>
                            <Grid item xs={12} >
                                    <FormControl fullWidth>
                                        <InputLabel id="status">Status</InputLabel>
                                        <JumboSelectField
                                            labelId="status"
                                            id="status"
                                            name="status"
                                            value={updateInputValue(classData, values, 'status')}
                                            label="Status"
                                            disabled={!hasPermission('Turmas', 'editar')}
                                        > 
                                            <MenuItem value={'Aprovado'}>Aprovado</MenuItem>
                                            <MenuItem value={'Reprovado'}>Reprovado</MenuItem>
                                            <MenuItem value={'Ativo'}>Ativo</MenuItem>
                                            <MenuItem value={'Desistente'}>Desistente</MenuItem>
                                            <MenuItem value={'Excluído'}>Excluído</MenuItem>
                                            <MenuItem value={'Outro'}>Outro</MenuItem>
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                {values.status == 'Reprovado' && 
                                    <Grid item xs={12} >
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="motivo_reprovacao">Motivo de reprovação</InputLabel> */}
                                            <JumboTextField
                                                fullWidth
                                                name="motivo_reprovacao"
                                                label="Motivo de reprovação"
                                                multiline
                                                rows={4}
                                                value={updateInputValue(classData, values, 'motivo_reprovacao')}
                                                disabled={!hasPermission('Turmas', 'editar')}
                                                sx={{ background: '#ffffff'}}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12} >
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="observacao">Observação</InputLabel> */}
                                        <JumboTextField
                                            fullWidth
                                            name="observacao"
                                            label="Observação"
                                            multiline
                                            rows={4}
                                            value={updateInputValue(classData, values, 'observacao')}
                                            disabled={!hasPermission('Turmas', 'editar')}
                                            sx={{ background: '#ffffff'}}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sx={{textAlign: 'center'}}> 
                                    <LoadingButton
                                        color="success"
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                        loading={isSubmitting}
                                        disabled={!hasPermission('Turmas', 'editar')}
                                    >
                                        Atualizar
                                    </LoadingButton>

                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                </Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalChangeStatus