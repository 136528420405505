import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Button, CircularProgress, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, Zoom} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Link } from "react-router-dom";
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVStyled, Item } from '../classes/ClassItem';
import VolunteerItem from './VolunteerItem';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';


const VolunteerList = () => {  
    const {theme} = useJumboTheme();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [users, setUsers] = useState(null)
    const [select1Value, setSelect1Value] = useState(null);
    const [select2Value, setSelect2Value] = useState(null);
    const [select3Value, setSelect3Value] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const animatedComponents = makeAnimated();

    const { hasPermission } = useContext(PermissionContext);

    const handleSelect1Change = (selected) => {
        setSelect1Value(selected.length == 0 ? null : selected);
    };

    const handleSelect2Change = (selected) => {
    setSelect2Value(selected.length == 0 ? null : selected);
    };

    const handleSelect3Change = (selected) => {
    setSelect3Value(selected.length == 0 ? null : selected);
    };
    const [areas, setAreas] = useState(null)
    const [departments, setDepartments] = useState(null)
    const getAreas = useCallback(async () => {
        try {
            ApiService.get('/areas')
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get('/departamentos')
            .then((response) => {
              setDepartments(response.data);
              console.log('response.data', response.data)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

      useEffect(() => {
        getAreas();
        getDepartments();
      }, []);

      useEffect(() => {
        console.log('atualizou')
      }, [setAreas, areas]);

      console.log(areas)

    const optionsAreas = areas?.filter(result => result?.area_id != null).map((result) => {
        return {
          value: result.area_id,
          label: result.nome
        };
    });

    const optionsDepartments = departments?.filter(result => result?.departamento_id != null).map((result) => {
        return {
          value: result.departamento_id,
          label: result.nome
        };
    });

    const optionsStatus = ['ativo', 'inativo'].map((result) => {
        return {
            value: result,
            label: result
        }
    })
    
    const searchData = {
        select1Value,
        select2Value,
        select3Value,
    };

    const getUsers = useCallback(async (searchData) => {
        try {
            ApiService.get('/voluntarios', {params: {searchData}})
            .then((response) => {
                console.log(response.data)
              setUsers(response.data);
              setIsLoading(false)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      function handleSubmit() {
        // if (!select1Value?.length && !select2Value?.length && !select3Value?.length && !startDate && !endDate) {
        //   return;
        // }
  
        const searchData = {
          select1Value,
          select2Value,
          select3Value,
        };
  
        console.log('searchData', searchData)
  
        getUsers(searchData);
      }

    useEffect(() => {
    getUsers(searchData);
    }, []);

    useEffect(() => {
    console.log('atualizou')
    }, [setUsers, users]);

    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(searchTerm == undefined) return;

        if(searchTerm == '') {
            setLoading(true)
            searchUser(searchTerm)
            setLoading(false)
        }

        const delayDebounceFn = setTimeout(() => {
        setLoading(true)
        searchUser(searchTerm)
        setLoading(false)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const searchUser = useCallback(async (e) => {
        console.log('pesquisar', e)
        try {
            ApiService.get('/voluntarios', {params: {search: e}})
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
        }
    }, []);

    const small = window.matchMedia('(max-width:1330px)');

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Voluntários"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Grid item xs={1} sx={{textAlign: 'center'}}> 
                <Link to="/app/novo-voluntariado" style={{ textDecoration:'none', pointerEvents: !hasPermission('Voluntariado', 'criar') ? 'none' : ''  }}>
                    <LoadingButton
                        color="success"
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={!hasPermission('Voluntariado', 'criar') ? true : false}
                        sx={{maxWidth: {md: '200px', mt: '1rem'}, marginBottom: '2rem'}}
                    >
                        Novo Cadastro
                    </LoadingButton>
                </Link>
            </Grid>
            <Grid container spacing={3} justifyContent={'center'} alignContent={'center'} sx={{mb: '2rem', justifyContent: 'center'}}>
                <Grid item xs={3}>
                    <Select 
                        id="select-one"
                        isMulti 
                        options={optionsStatus}
                        components={animatedComponents}
                        value={select1Value}
                        onChange={handleSelect1Change}
                        placeholder="Selecione status"
                        styles={{
                            menu: (list) => ({
                              ...list,
                              zIndex: 3,
                            }),
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        id="select-two"
                        isMulti 
                        options={optionsAreas}
                        components={animatedComponents}
                        value={select2Value}
                        onChange={handleSelect2Change}
                        placeholder="Selecione área"
                    />
                </Grid>
                <Grid item xs={small.matches ? 4 : 3}>
                    <Select
                        id="select-three"
                        isMulti 
                        options={optionsDepartments}
                        components={animatedComponents}
                        value={select3Value}
                        onChange={handleSelect3Change}
                        placeholder="Selecione departamento"
                    />
                </Grid>
                    
                    <Grid item xs={small.matches ? 2 : 3}>
                        <Button 
                        fullWidth 
                        variant={'contained'} 
                        onClick={handleSubmit}
                        >
                            Filtrar
                        </Button>
                    </Grid>
            </Grid>
        
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                
                <JumboSearch
                    onChange={(e) => setSearchTerm(e)}
                    placeholder="Busque por nome, email, celular e cpf."
                    sx={{
                        width: '440px',
                        marginBottom: '1rem'
                    }}
                />

            </Div>
            <>
                {
                    (users != null && users.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                }

                {
                    users && users.map((user, key) => (
                        <VolunteerItem item={user} setUsers={(data) => setUsers([...data])} users={users} key={key}/>
                    ))
                }

                {isLoading && <CircularProgress/>}
            </>
        </Paper>
    </JumboContentLayout>
  )
}

export default VolunteerList