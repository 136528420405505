import React, { useContext } from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography, Grid, Chip} from "@mui/material";
import Button from "@mui/material/Button";
import { PermissionContext } from 'app/contexts/PermissionContext';

const ListItemVolunteerInterested = ({item, includeStudentToClass}) => {
    const { hasPermission } = useContext(PermissionContext);

    return (
        <ListItem sx={{p: theme => theme.spacing(1, 3)}}>
            <Grid container spacing={2} alignContent={'center'}>
                <Grid item lg={1} md={1} xs={2} sx={{display: 'none'}}>
                    <Avatar alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`}/>
                </Grid>


                <Grid item lg={3} md={5} xs={4} >
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Nome
                    </Typography>
                    <Typography variant={"body1"}>{item.nome}</Typography>
                </Grid>
                <Grid item lg={3} md={5} xs={3} >
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        CPF
                    </Typography>
                    <Typography variant={"body1"}>{item.cpf}</Typography>
                </Grid>
                <Grid item lg={3} sx={{ display: {xs: 'none', md: 'none', lg: 'block'} }}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Email
                    </Typography>
                    <Typography variant={"body1"}>{item.email}</Typography>
                </Grid>
                <Grid item lg={2} md={1} xs={1}>
                    <Typography
                        fontSize={"12px"}
                        variant={"h6"}
                        color={"text.secondary"}
                        mb={.25}
                    >
                        Curso de voluntariado?
                    </Typography>
                    {item.curso_de_voluntario 
                        ? <Chip label="Sim" color="success" size='small'/>
                        : <Chip label="Não" color="error" size='small'/>
                    }
                    
                </Grid>
                <Grid item lg={1} md={1} xs={1} >
                    <Button
                        size={"small"}
                        variant={"contained"}
                        color={"success"}
                        disableElevation
                        onClick={() => includeStudentToClass(item)}
                        // {...(item.follow) ? {color: "inherit"} : {}}
                        disabled={!hasPermission('Voluntariado', 'editar') || item.curso_de_voluntario === false}
                        sx={{
                            minWidth: 78,
                            textTransform: 'none',
                            p: theme => theme.spacing(.5, 1.5)
                        }}
                    >
                        Incluir
                    </Button>
                </Grid>

            </Grid>
            
            
        </ListItem>

    )
};
/* Todo item, includeStudentToClass prop define */
export default ListItemVolunteerInterested;
