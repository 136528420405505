import React from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";


const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const {setAuthToken} = useJumboAuth();
    const getNameFromLocalStorage = localStorage.getItem("storedUsername")
    const getEmailFromLocalStorage = localStorage.getItem("storedUserEmail")
    const getImageFromLocalStorage = localStorage.getItem("storedUserImage");
    const getStoredPermissionsFromLocalStorage = JSON.parse(localStorage.getItem("storedPermissions"))

    const onLogout = () => {
        setAuthToken(null);
        navigate("/login");
    };

    const uniqueNames = [...new Set(getStoredPermissionsFromLocalStorage?.map(p => p.perfil.nome))];
    
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={getImageFromLocalStorage ?`${process.env.REACT_APP_API_KEY}/images/${getImageFromLocalStorage}` : '#'}
                        sizes={"small"}
                        sx={{boxShadow: 25, cursor: 'pointer'}}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    //width: '220px',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={getImageFromLocalStorage ?`${process.env.REACT_APP_API_KEY}/images/${getImageFromLocalStorage}` : '#'} alt={getNameFromLocalStorage} sx={{width: 60, height: 60, mb: 2}}/>
                    <Typography sx={{textAlign: 'center'}} variant={"h5"}>{getNameFromLocalStorage}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{getEmailFromLocalStorage}</Typography>
                    {uniqueNames?.map((name, index) => (
                        <Typography 
                            key={index} 
                            variant={"body1"} 
                            color="text.secondary"
                            sx={{textAlign: 'center'}}
                        >{name}
                        </Typography>
                    ))}
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <EditOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Edit Profile" sx={{my: 0}}/>
                        </ListItemButton>
                        
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{my: 0}}/>
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
