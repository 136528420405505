import React, { useCallback, useState, useContext, useEffect } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, AccordionDetails, Card, FormControl, Grid, InputLabel, MenuItem, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import {TIME} from "../../../utils/constants/time";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";
import { PermissionContext } from 'app/contexts/PermissionContext';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { LinkStyle } from 'app/pages/app/volunteersInterested/VolunteerInterestedItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation } from 'react-router-dom';

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const VolunteerInterestedHistoryItem = ({ item , handleChange, handleClose}) => {
    const [expanded, setExpanded] = useState(false);
    const [areas, setAreas] = useState(null);
    const [departments, setDepartments] = useState(null);
    const { hasPermission } = useContext(PermissionContext)
    const location = useLocation();

    const toggleAccordion = () => setExpanded(!expanded);

    const Swal = useSwalWrapper();

    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    
    const getAreas = useCallback(async () => {
        try {
            ApiService.get('/areas')
            .then((response) => {
              setAreas(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get('/departamentos')
            .then((response) => {
              setDepartments(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        getAreas();
        getDepartments();
    }, []);

    const verifyAreasandDepartments = () => {
        if(item.Areas == null) {
            if(!departments?.some(y => y.departamento_id === item.departamento_id)) {
                return<Typography variant={"body1"}>Departamento excluído</Typography>
            }else {
                return <Typography variant={"body1"}>{item.Departamento?.nome}</Typography>
            }
        }
        if(item.Departamento == null) {
            if(!areas?.some(y => y.area_id === item.area_id)) {
                return <Typography variant={"body1"}>Área excluída</Typography>
            } else {
                return <Typography variant={"body1"}>{item.Areas?.nome}</Typography>
            }
        }
    };

    return (
        <Card sx={{mb: 2}} style={{ display: 'flex', flexDirection: 'row' }}>
            
            <Accordion expanded={expanded} onChange={handleChange} square sx={{borderRadius: 2, width: '100%'}}>
                <AccordionSummary
                    expandIcon={ item.Horario_voluntariado || item.Trabalho_Voluntario_Fora_Feesp
                        ? 
                        <ExpandMoreIcon
                            onClick={toggleAccordion}
                        /> 
                        : 
                        null
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '25%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Interesse
                        </Typography>
                        <Typography variant={"body1"}>
                            {verifyAreasandDepartments()}
                        </Typography>
                    </Div>
                   
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '25%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Local
                        </Typography>
                        <Typography variant={"body1"}>{item.local}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '25%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Realizou vivência prática?
                        </Typography>
                        <Typography variant={"body1"}>
                            {item.teve_vivencia_pratica ? 'Sim' : 'Não'}
                        </Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '25%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Status
                        </Typography>
                        <Typography variant={"body1"}>{item.status}</Typography>
                    </Div>
                    
                </AccordionSummary>
                {(item.Horario_voluntariado || item.Trabalho_Voluntario_Fora_Feesp) && 
                    <>
                        <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                            <Typography variant={"h5"} sx={{mb: 2}}>Realiza trabalho voluntario fora da feesp?</Typography>
                            {
                                item.Trabalho_Voluntario_Fora_Feesp.length > 0  
                                ? 
                                (
                                    item.Trabalho_Voluntario_Fora_Feesp.map((x) => (
                                    <Grid container spacing={3} sx={{marginBottom: {xs: '1rem'}}}>
                                    <Grid item xs={4} >
                                        <JumboTextField
                                            fullWidth
                                            name="nome_trabalho_voluntario_fora"
                                            label="Nome do trabalho voluntario"
                                            value={x.nome_trabalho_voluntario_fora}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <JumboTextField
                                                fullWidth
                                                name="local_fora"
                                                label="Local"
                                                value={x.local_fora}
                                                disabled={true}
                                            />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <JumboTextField
                                            fullWidth
                                            name="data_inicio_voluntario_fora"
                                            type="date"
                                            label="Data de inicio"
                                            value={x.data_inicio_voluntario_fora}
                                            sx={{ background: '#ffffff'}}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true} 
                                        />
                                    </Grid>
                                    </Grid>
                                ))
                                )
                                :
                                (
                                    <Typography>Sem resultados correspondentes</Typography>
                                )
                            }
                        </AccordionDetails>
                        <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                            <Typography variant={"h5"} sx={{mb: 2}}>Horário de preferência</Typography>
                            {
                               item.Horario_voluntariado.length > 0 
                               ? 
                                (
                                    item.Horario_voluntariado.map((x) => (
                                    <Grid container spacing={3} sx={{marginBottom: {xs: '1rem'}}}>
                                        <Grid item xs={4} sx={{marginBottom: {xs: '0.5rem'}}}>
                                            <FormControl fullWidth>
                                                <InputLabel id="dia_da_semana">Dia da Semana</InputLabel>
                                                <JumboSelectField
                                                    labelId="dia_da_semana"
                                                    id="dia_da_semana"
                                                    name="dia_da_semana"
                                                    label="dia_da_semana"
                                                    value={x.dia_da_semana}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={true}
                                                >
                                                    <MenuItem value={'Domingo'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Domingo') ? 'none' : 'block' }}>Domingo</MenuItem>
                                                    <MenuItem value={'Segunda'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Segunda') ? 'none' : 'block' }}>Segunda</MenuItem>
                                                    <MenuItem value={'Terça'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Terça') ? 'none' : 'block' }}>Terça</MenuItem>
                                                    <MenuItem value={'Quarta'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Quarta') ? 'none' : 'block' }}>Quarta</MenuItem>
                                                    <MenuItem value={'Quinta'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Quinta') ? 'none' : 'block' }}>Quinta</MenuItem>
                                                    <MenuItem value={'Sexta'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Sexta') ? 'none' : 'block' }}>Sexta</MenuItem>
                                                    <MenuItem value={'Sabado'} style={{ display: item.Horario_voluntariado.some(item => item.dia_da_semana == 'Sabado') ? 'none' : 'block' }}>Sábado</MenuItem>
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputMask
                                                    mask="99:99"
                                                    value={x.horario_inicio}
                                                    disabled={true}
                                                >
                                                    {() => 
                                                    <JumboTextField
                                                    fullWidth
                                                    name="horario_inicio"
                                                    label="Horário Inicial"
                                                    disabled={true}
                                                />
                                                    }
                                                </InputMask>
                                    
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputMask
                                                    mask="99:99"
                                                    value={x.horario_fim}
                                                    disabled={true}
                                                >
                                                    {() => 
                                                    <JumboTextField
                                                    fullWidth
                                                    name="horario_fim"
                                                    label="Horário Final"
                                                    disabled={true}
                                                />
                                                    }
                                                </InputMask>
                                    
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )
                                ))
                                :
                                (
                                    <Typography>Sem resultados correspondentes</Typography>
                                )
                            }
                        </AccordionDetails>

                    </>
                }
            </Accordion>
            <Item sx={{ml: 'auto', display: {xs: 'none', sm: 'flex'}, alignSelf: 'center', flexDirection: 'row', gap: 2}} disableElevation>
                
                <LinkStyle
                    to={`/app/listar-voluntariado-interesse-resume/${item.interesse_voluntario_id}`}
                    state={{ backUrl: location.pathname }}
                    disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                    
                >
                    <ArrowForwardIosIcon/>
                        
                </LinkStyle>
            </Item>
        </Card>
    );
};
/* Todo item prop define */
export default VolunteerInterestedHistoryItem;
