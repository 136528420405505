import React from 'react'
import { Box, Paper, Typography} from '@mui/material'

const InfosAboutCreateAndUpdate = ({data, data_quem_criou, data_quem_atualizou, paperBg = '#ffffff'}) => {
    const moment = require('moment-timezone');
    const dateTime = data && data?.updatedAt;

    let dateBr;
    let timeBr;
    if(dateTime) {
        dateBr = moment(dateTime).tz('America/Sao_Paulo').format('DD/MM/YYYY');
        timeBr = moment(dateTime).tz('America/Sao_Paulo').format('HH:mm:ss')
    }

    return (
        <Box display='flex' justifyContent='center' width='100%' gap='1rem' marginBottom='1rem'>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Quem criou?
                    </Typography>
                    <Typography variant='h6'>
                    {/*  {users?.quem_aceitou?.nome} */}
                    {data_quem_criou}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Quem atualizou? 
                    </Typography>
                    <Typography variant='h6'>
                        {/* {users?.quem_atualizou?.nome} */}
                        {data_quem_atualizou}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Data da ultima atualização 
                    </Typography>
                    <Typography variant='h6'>
                        {dateBr}
                    </Typography>
                </Box>
            </Paper>
            <Paper elevation={1} sx={{p: '10px', background: paperBg}}>
                <Box textAlign='center'>
                    <Typography variant='h6'>
                        Horário da ultima atualização
                    </Typography>
                    <Typography variant='h6'>
                        {timeBr}
                    </Typography>
                </Box>
            </Paper>
        </Box>
    )
}

export default InfosAboutCreateAndUpdate