import React, { useState, useCallback, useEffect } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, Stack,Switch,TextField,Typography,useMediaQuery} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import { CheckBox } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import CourseHistoryItem from "./CourseHistoryItem";

const validationSchema = yup.object().shape({
    // status:
    // observacao:
    // turma_id:
    // turma_id_anterior:
    // pessoa_id:
});

const ModalCourseHistory = ({ handleClose }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const { id } = useParams()
    const [courseHistory, setCourseHistory] = useState([]);
    
    const getCourseHistory = useCallback(async () => {
        try {
            ApiService.get(`/historico-cursos/${id}`)
            .then((response) => {
                setCourseHistory(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      useEffect(() => {
        if(id) getCourseHistory();
      }, []);  

  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>Histórico de Cursos</Typography>
                <React.Fragment>
                    {courseHistory.length > 0 ?
                        courseHistory.map((data, key) => (
                            <CourseHistoryItem item={data} handleClose={handleClose}/>
                        ))
                    : 'Nenhum histórico encontrado'}
                </React.Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalCourseHistory