import React from "react";
import PeopleIcon from '@mui/icons-material/People';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ClassIcon from '@mui/icons-material/Class';
import CategoryIcon from '@mui/icons-material/Category';
import HistoryIcon from '@mui/icons-material/History';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
    },
    {
       
        label: 'sidebar.menu.admin',
        type: "collapsible",
        icon: <ManageAccountsIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-areas",
                label: 'Áreas',
                type: "nav-item",
                //icon: <CategoryIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-departamentos",
                label: 'Departamentos',
                type: "nav-item",
                //icon: <HolidayVillageIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/log-de-atividades",
                label: 'Log de Atividades',
                type: "nav-item",
                //icon: <HistoryIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-perfis-de-acesso",
                label: 'Perfis',
                type: "nav-item",
                //icon: <ManageAccountsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/app/listar-usuarios-registrados",
                label: 'Usuários',
                type: "nav-item",
                //icon: <GroupsIcon sx={{fontSize: 20}}/>
            },
        
        ]
    },
    {
        uri: "/app/listar-usuarios",
        label: 'Pessoas',
        type: "nav-item",
        icon: <PeopleIcon sx={{fontSize: 20}}/>
    },
    {
       
        label: 'sidebar.menu.ensino',
        type: "collapsible",
        icon: <ClassIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-cursos",
                label: 'Cursos',
                type: "nav-item",
            },
            {
                uri: "/app/listar-turmas",
                label: 'Turmas',
                type: "nav-item",
            },
        
        ]
    },
    {
       
        label: 'sidebar.menu.voluntariado',
        type: "collapsible",
        icon: <VolunteerActivismIcon sx={{fontSize: 20}}/>,
        children: [
            {
                uri: "/app/listar-voluntariado",
                label: 'Voluntários',
                type: "nav-item"
            },
            
            {
                uri: "/app/listar-voluntariado-interesse",
                label: 'Interesse Voluntariado',
                type: "nav-item"
            },
        
        ]
    },
];

export default menus;
