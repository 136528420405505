import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Typography,useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Box, Input} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import axios from 'axios';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { COUNTRIES } from '../../../utils/constants/countries'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress';
import { PermissionContext } from 'app/contexts/PermissionContext';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CollectionsBookmarkOutlined } from '@mui/icons-material';

const validationSchema = yup.object().shape({
    nome: yup
        .string('Insira o nome do usuário')
        .required('Preenchimento obrigatório'),
    perfil_id: yup
        .string('Escolha o departamento')
        .required('Preenchimento obrigatório'),
    email: yup
        .string('Insira seu email')
        .email('Insira um email válido')
        .when(['cpf', 'rg'], {
            is: (cpf, rg) => !cpf && !rg,
            then: yup.string().required('Preenchimento obrigatório')
        })
        ,
    celular: yup
        .string()
        .required('Preenchimento obrigatório')
        .transform(value => value.replace(/[^\d]/g, ''))
        .min(14, "Preencha o número completo")
        .max(14, "Preencha o número completo")
        ,
    /* senha: yup
        .string()
        .required('Preenchimento obrigatório'),
    senhaConfirmacao: yup
        .string()
        .required('Preenchimento obrigatório')
        .oneOf([yup.ref('senha')], 'As senhas devem ser iguais') */
    // senha: yup
    //     .string()
    //     // .required('Preenchimento obrigatório')
    //     // .transform(value => value.replace(/[^\d]/g, ''))
    //     .min(8, "A senha deve ter pelo menos 8 dígitos")
    //     ,
    // senhaConfirmacao: yup
    //     .string()
    //     .required('Preenchimento obrigatório')
    //     // .transform(value => value.replace(/[^\d]/g, ''))
    //     .test('cell-match', 'As senhas devem ser iguais', function(value){
    //         return this.parent.senha === value
    //     })
    //     ,
}, []);

const validationSchemaWithPass = yup.object().shape({
    nome: yup
        .string('Insira o nome do usuário')
        .required('Preenchimento obrigatório'),
    perfil_id: yup
        .string('Escolha o departamento')
        .required('Preenchimento obrigatório'),
    email: yup
        .string('Insira seu email')
        .email('Insira um email válido')
        .when(['cpf', 'rg'], {
            is: (cpf, rg) => !cpf && !rg,
            then: yup.string().required('Preenchimento obrigatório')
        })
        ,
    celular: yup
        .string()
        .required('Preenchimento obrigatório')
        .transform(value => value.replace(/[^\d]/g, ''))
        .min(14, "Preencha o número completo")
        .max(14, "Preencha o número completo")
        ,
    senha: yup
        .string()
        .required('Preenchimento obrigatório'),
    senhaConfirmacao: yup
        .string()
        .required('Preenchimento obrigatório')
        .oneOf([yup.ref('senha')], 'As senhas devem ser iguais')

}, [])

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const UserRegistrationForm = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate();

    const { hasPermission } = useContext(PermissionContext);

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const small = window.matchMedia('(max-width:1000px)');

    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        nome: "",
        perfil_id: "",
        celular: "05511",
        email: "",
        senha: "",
        senhaConfirmacao: "",
        cpf: "",
        rg: ""

    }
    // const id = 48;
    const { id } = useParams()
    // const params = useParams();
    const { state } = useLocation();
    const release = state?.release ? true : false;
    // const { release } = state; // Read values passed on state
    console.log('release', release)
    const [user, setUser] = useState(initialValues);
    const [userImage, setUserImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    // const [senhaConfirmacao, setSenhaConfirmacao] = useState(false);
    const [roles, setRoles] = useState([]);

    const canCreate = hasPermission('Pessoas', 'criar');
    const canEdit = hasPermission('Pessoas', 'editar');
    const isDisabled = id ? !canEdit : !canCreate

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordConfirmation = (event) => {
        event.preventDefault();
    };

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });

        //  Redireciona se mensagem de retorno for de sucesso
        // if(variant == 'success' && (!userImage || (userImage && type))){

            setTimeout(function(){
                navigate("/app/listar-usuarios-registrados")

            }, 2000)
        // }
    };

    const getUser = useCallback(async () => {
        try {
            ApiService.get(`/pessoa/${id}`)
            .then((response) => {
                let user = response.data.user[0];
                user.perfil_id = user.usuario_perfil[0]?.perfil_id;

                setUser(user);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getRoles = useCallback(async () => {
        try {
            ApiService.get(`/perfil`)
            .then((response) => {
              setRoles(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getUser();
        getRoles();
    }, []);

    console.log('user', user)
    const handleSubmit = async (values, { setSubmitting, setFieldError, errors }) => {
        console.log('values', values)
        console.log('errors', errors)
        var userID = id;
        let data = new FormData()


        if(id){
            ApiService.put(`/usuarios-registrados/${id}`, {
                values
               })
               .then(() => {
                    toast('success', 'Atualizado com sucesso')
                })
               .catch((error) => {
                    const target = error.response.data.error.meta.target;
                    let message = 'Ocorreu um erro'
                    
                    toast('error', message)

                   if (error.response.data) {
                        console.log(error.response.data.error.meta.target);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
               })
        } else {
            await ApiService.post('/pessoa', {
                values
               })
              .then((response) => {
                userID = response.data.pessoa_id
                toast('success', 'Criado com sucesso')
              })
              .catch((error) => {
                const target = error.response.data.error.meta.target;
                let message = 'Ocorreu um erro'

                toast('error', message)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
              })
        }

        setSubmitting(false);
    } 

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Usuários"}
                subtitle={id ? "Editar" : "Cadastro de Usuários"}
                titleUrl={"/app/listar-usuarios-registrados"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '15px', background: '#f9f9f9'}}>
                
                        <Formik
                            initialValues={user}
                            // validationSchema={null}
                            validationSchema={release ? validationSchemaWithPass : validationSchema}
                            enableReinitialize
                            validateOnChange={false}
                            //validateOnBlur={true}
                            onSubmit={handleSubmit}
                        >
                            {({values, isSubmitting, handleChange, setFieldValue, touched, errors, setTouched, setFieldTouched}) => (
                                <Form style={{width: '100%'}} noValidate autoComplete='off'>
                                    <Grid container alignContent={'center'}>
                                        <Grid container spacing={3} alignContent={'center'} 
                                            sx={
                                                {   mt: '0.8rem',
                                                    background: '#EFF0F2',
                                                    marginLeft: 0,
                                                    padding: '0 24px 24px 0'
                                                }
                                            }
                                        >
                                            
                                            <Grid item lg={4} md={6} xs={12} >
                                                <JumboTextField
                                                    fullWidth
                                                    name="nome"
                                                    label="Nome"
                                                    value={updateInputValue(user, values, 'nome')}
                                                    disabled={isDisabled}
                                                    sx={{ background: '#ffffff'}}
                                                />
                                            </Grid>

                                            <Grid item lg={3} md={6} xs={12} >
                                                <JumboTextField
                                                    fullWidth
                                                    name="email"
                                                    label="Email"
                                                    value={updateInputValue(user, values, 'email')}
                                                    disabled={isDisabled}
                                                    sx={{ background: '#ffffff'}}
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={6} xs={12} >
                                                <InputMask
                                                    mask="+999(99)99999-9999"
                                                    maskPlaceholder={null}
                                                    // error={touched.celular ? errors.celular : undefined}
                                                    // touched={touched.celular ? 'true' : 'false'}
                                                    value={updateInputValue(user, values, 'celular')}
                                                    onChange={handleChange}
                                                    disabled={isDisabled}
                                                    disabledUnderline={isDisabled}
                                                >
                                                    {(inputProps) =>
                                                        <JumboTextField
                                                            {...inputProps}
                                                            fullWidth
                                                            name="celular"
                                                            label="Celular"
                                                            sx={{ background: '#ffffff'}}
                                                            disabled={inputProps.disabledUnderline}
                                                        /> 
                                                    }
                                                </InputMask>
                                            </Grid>

                                            <Grid item lg={2} md={6} xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="perfil_id">Perfil</InputLabel>
                                                    <JumboSelectField
                                                        labelId="perfil_id"
                                                        id="perfil_id"
                                                        name="perfil_id"
                                                        value={updateInputValue(user, values, 'perfil_id')}
                                                        label="Perfil"
                                                        disabled={isDisabled}
                                                        sx={{ background: '#ffffff'}}
                                                    >
                                                        {roles.length >0 && roles.map((x) => 
                                                            <MenuItem value={x.perfil_id}>{x.nome}</MenuItem>
                                                        )}
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    id="senha"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="senha"
                                                    InputProps={{ 
                                                        endAdornment: 
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                        </InputAdornment>,
                                                    }}
                                                    sx={{ background: '#ffffff'}}
                                                    label="Senha"
                                                />
                                            
                                            </Grid>

                                            <Grid item md={6} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    id="senhaConfirmacao"
                                                    type={showPasswordConfirmation ? 'text' : 'password'}
                                                    name="senhaConfirmacao"
                                                    InputProps={{
                                                        endAdornment: 
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordConfirmation}
                                                                onMouseDown={handleMouseDownPasswordConfirmation}
                                                                edge="end"
                                                                >
                                                        {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                        </InputAdornment>,
                                                    }}
                                                    sx={{ background: '#ffffff'}}
                                                    label="Confirmar senha"
                                                />
                                            </Grid> 

                                        <Grid item xs={12} sx={{textAlign: 'center', mt: '1rem'}}> 
                                            <LoadingButton
                                                color="success"
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                                loading={isSubmitting}
                                                disabled={isDisabled}
                                            >
                                                {id ? 'Atualizar' : 'Cadastrar'}
                                            </LoadingButton>

                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default UserRegistrationForm