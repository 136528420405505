import React, { useState, useCallback, useEffect, Fragment } from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Grid,
  Link,
  Paper,
  Stack,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { ApiService } from "app/servicesTwo/ApiService";
import ProfileUserListItem from "./ProfileUserListItem";
import JumboSearch from "@jumbo/components/JumboSearch";
import { useParams } from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const validationSchema = yup.object({
  email: yup.string("Insira seu email").email("Insira um email válido"),
  senha: yup.string("Insira sua senha"),
  nome: yup.string("Insira seu nome").required("Preenchimento obrigatório"),
  cpf: yup.string(),
  rg: yup.string().max(11, "Deve ter no máximo 11 digitos"),
  data_nascimento: yup
    .date()
    .max(new Date(), "Não é possivel incluir uma data futura")
    .required("Preenchimento obrigatório"),
  celular: yup.string(),
});

const ProfileUserList = ({ profileUser, setProfileUser, profiles, handleInputFocus, handleInputBlur }) => {

  const { theme } = useJumboTheme();
  const { id } = useParams();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  // const [profileUser, setProfileUser] = useState([]);
  const [allProfilesUser, setAllProfilesUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  console.log(allProfilesUser)
  useEffect(() => {
    console.log("searchTerm", searchTerm);
    if (searchTerm == undefined || searchTerm == "") {
      setProfileUser(allProfilesUser);

      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchClass(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getClassStudents = useCallback(async () => {
    try {
      ApiService.get(`/perfis/${id}/usuarios`)
        .then((response) => {
          console.log("response.data", response.data);
          const students = (response.data.Usuario_Perfil).map((x) => ({
            
            ...x.pessoa,
            perfil_id: x.perfil_id,
            usuario_perfil_id: x.usuario_perfil_id
          }));
          setAllProfilesUser(students);
          setProfileUser(students);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchClass = useCallback(
    async (e) => {
      // const found = allProfilesUser.find(element => (element.nome).search(e) >= 0);
      // // console.log('found', found)
      // if(found){
      //   setProfileUser([found]);
      // } else {
      //   setProfileUser([]);
      // }

      try {
        ApiService.get(`/perfis/${id}/usuarios`, { params: { search: e } })
          .then((response) => {
            console.log("retorno", response.data);
            const students = response.data.Usuario_Perfil.map((x) => ({
              ...x.pessoa,
              
              usuario_perfil_id: x.usuario_perfil_id,
            }));
            setProfileUser(students);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [profileUser]
  );

  useEffect(() => {
    getClassStudents();
    
  }, []);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "40px" }}>
        <Grid item xs={1} sx={{ textAlign: "center" }}>
          <JumboSearch
            onChange={(e) => setSearchTerm(e)}
            placeholder="Buscar por Nome, Email, CPF ou Status"
            sx={{
              width: "440px",
              marginBottom: "1rem",
            }}
            onFocus={handleInputFocus} 
            onBlur={handleInputBlur}
          />
        </Grid>

        <Fragment>
          {profileUser.length > 0 &&
            profileUser.map((student, key) => (
              <ProfileUserListItem
                item={student}
                setProfileUser={(data) => setProfileUser([...data])}
                profileUser={profileUser}
                key={key}
                profiles={profiles}
              />
            ))}
          {profileUser && profileUser.length == 0 && (
            <Typography>Sem resultados correspondentes</Typography>
          )}
        </Fragment>
      </Paper>
    </JumboContentLayout>
  );
};

export default ProfileUserList;
