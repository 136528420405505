import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Typography,useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Box, Input} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ModalCourseHistory from "../course_histories/ModalCourseHistory";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import axios from 'axios';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { COUNTRIES } from '../../../utils/constants/countries'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress';
import { PermissionContext } from 'app/contexts/PermissionContext';

const validationSchema = yup.object().shape({
    nome: yup
        .string('Insira o nome do curso')
        .required('Preenchimento obrigatório'),
    departamento_id: yup
        .string('Escolha o departamento')
        .required('Preenchimento obrigatório'),
}, []);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const CourseForm = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate();

    const { hasPermission } = useContext(PermissionContext);

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const small = window.matchMedia('(max-width:1000px)');

    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        nome: "",
        departamento_id: "",
        imagem_curso: ""
    }

    const { id } = useParams()
    const [course, setCourse] = useState(initialValues);
    const [courseImage, setCourseImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState([]);

    const canCreate = hasPermission('Cursos', 'criar');
    const canEdit = hasPermission('Cursos', 'editar');
    const isDisabled = id ? !canEdit : !canCreate

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });

        //  Redireciona se mensagem de retorno for de sucesso
        if(variant == 'success' && (!courseImage || (courseImage && type))){
            setTimeout(function() {
                navigate("/app/listar-cursos")

            }, 2000)
        }
    };

    const getCourse = useCallback(async () => {
        try {
            ApiService.get(`/cursos/${id}`)
            .then((response) => {
              setCourse(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    const getDepartments = useCallback(async () => {
        try {
            ApiService.get(`/departamentos`)
            .then((response) => {
              setDepartments(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getCourse();
        getDepartments();
    }, []);

    const handleChangeImage = (e) => {
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        setCourseImage(e.target.files[0])
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        var courseID = id;
        let data = new FormData()
        data.append("imagem_curso", courseImage)

        if(id){
            ApiService.put(`/cursos/${id}`, {
                values
            })
            .then(() => {
                toast('success', 'Atualizado com sucesso')
            })
            .catch((error) => {
                const target = error.response.data.error.meta.target;
                let message = 'Ocorreu um erro'
                
                toast('error', message)

                if (error.response.data) {
                    console.log(error.response.data.error.meta.target);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })

            if(courseImage) {
                ApiService.put(`/cursos/atualizar-imagem/${courseID}`, data)
                .then(() => {
                toast('success', 'Imagem atualizada com sucesso', true)
                })
                .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
            }

        } else {
            await ApiService.post('/cursos', {
                values
               })
            .then((response) => {
            courseID = response.data.curso_id
            toast('success', 'Criado com sucesso')
            })
            .catch((error) => {
            const target = error.response.data.error.meta.target;
            let message = 'Ocorreu um erro'

            toast('error', message)

            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('error', error.message);
                }
            })

            if(courseImage) {
                ApiService.put(`/cursos/atualizar-imagem/${courseID}`, data)
                .then(() => {
                toast('success', 'Criado com sucesso', true)
                })
                .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('error', error.message);
                    }
                })
            }
        }

        
        setSubmitting(false);
    } 

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Cursos"}
                subtitle={id ? "Editar" : "Cadastro de Cursos"}
                titleUrl={"/app/listar-cursos"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '15px', background: '#f9f9f9'}}>
                
                        <Formik
                            initialValues={course}
                            // validationSchema={null}
                            validationSchema={validationSchema}
                            enableReinitialize
                            validateOnChange={false}
                            //validateOnBlur={true}
                            onSubmit={handleSubmit}
                        >
                            {({values, isSubmitting, handleChange, setFieldValue, touched, errors, setTouched, setFieldTouched}) => (
                                <Form style={{width: '100%'}} noValidate autoComplete='off'>
                                    <Grid container alignContent={'center'}>
                                        <Grid container spacing={3} alignContent={'center'} 
                                            sx={
                                                {   mt: '0.8rem',
                                                    background: '#EFF0F2',
                                                    marginLeft: 0,
                                                    padding: '0 24px 24px 0'
                                                }
                                            }
                                        >
                                            <Grid item lg={3} xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                {previewImage && 
                                                    <img src={previewImage} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                                }
                                                {values.imagem_curso && !previewImage && 
                                                    <img src={`${process.env.REACT_APP_API_KEY}/images/${values.imagem_curso}`} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                                }
                                                <Button 
                                                    variant="contained" 
                                                    component="label" 
                                                    color="info"
                                                    disabled={isDisabled}
                                                    sx={{ height: '53.13px',fontSize: {xs: '0.7rem'} }}
                                                >
                                                    Adicionar foto
                                                    <input type="file" hidden onChange={handleChangeImage}/>
                                                </Button>
                                                
                                            </Grid>
                                            
                                            <Grid item lg={6} xs={12} >
                                                <JumboTextField
                                                    fullWidth
                                                    name="nome"
                                                    label="Título do curso"
                                                    value={updateInputValue(course, values, 'nome')}
                                                    disabled={isDisabled}
                                                    sx={{ background: '#ffffff'}}
                                                />
                                            </Grid>

                                            <Grid item lg={3} xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="departamento_id">Departamento</InputLabel>
                                                    <JumboSelectField
                                                        labelId="departamento_id"
                                                        id="departamento_id"
                                                        name="departamento_id"
                                                        value={updateInputValue(course, values, 'departamento_id')}
                                                        label="Departamento"
                                                        disabled={isDisabled}
                                                        sx={{ background: '#ffffff'}}
                                                    >
                                                        {departments.length >0 && departments.map((x) => 
                                                            <MenuItem value={x.departamento_id}>{x.nome}</MenuItem>
                                                        )}
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>

                                        <Grid item xs={12} sx={{textAlign: 'center', mt: '1rem'}}> 
                                            <LoadingButton
                                                color="success"
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                                loading={isSubmitting}
                                                disabled={isDisabled}
                                            >
                                                {id ? 'Atualizar' : 'Cadastrar'}
                                            </LoadingButton>

                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default CourseForm