import React, {useState} from 'react';
import {Card, CardContent, IconButton, TextField, Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter} from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {alpha} from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {getAssetPath} from "../../utils/appHelpers";
import { ApiService } from 'app/servicesTwo/ApiService';
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { LoadingButton } from '@mui/lab';
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Insira um email válido')
        .required('Preenchimento obrigatório')
    ,   
});

const ForgotPassword = () => {

    const Swal = useSwalWrapper();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const linkInvalid = queryParams.get('linkInvalid') === 'true';
    const errorMessage = location.state && location.state.message;
    console.log(errorMessage)

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
      
    };


    const handleSubmit = async (email, setSubmitting) => {
        await ApiService.post('/esqueci-senha/', {email})
        .then((res) => {
            console.log(res.data.message)
            toast('success', res.data.message)
        })
        .catch((err) => {
            console.log(err.response.data.error)
            toast('error', err.response.data.error)
        })
        setSubmitting(false)
    }

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            <Card sx={{maxWidth: '100%', width: 360, mb: 4}}>
                <Div sx={{position: 'relative', height: '200px'}}>
                    <CardMedia
                        component="img"
                        alt="logo feesp"
                        height="200"
                        image={`/images/logos/logo.png`}
                    />
                    <Div sx={{
                        flex: 1,
                        inset: 0,
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        //alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: alpha('#0267a0', .65),
                        p: theme => theme.spacing(3),
                    }}>
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}>
                            Esqueci a senha
                        </Typography>
                        <Typography
                            variant={'h3'}
                            sx={{
                                color: 'common.white',
                                fontSize: '1rem',
                                mb: 0
                            }}
                        >
                            Você receberá um email com um link de redefinição de senha. Basta clicar no link e seguir as instruções para criar uma nova senha. 
                        </Typography>
                    </Div>
                </Div>
                <CardContent>
                    {linkInvalid &&
                        <Div 
                            sx={{
                                background: 'red', 
                                display: 'flex', 
                                alignItems: 'center',
                                color: '#ffffff',
                                gap: '1rem',
                                padding: '0.5rem'
                            }}
                        >
                            <ErrorOutlineIcon/>
                            <span>
                                {errorMessage}
                            </span> 
                        </Div>
                    }
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            handleSubmit(data.email, setSubmitting);
                        }}
                    >
                        {({isSubmitting, handleChange, values}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mb: 3, mt: 1}}>
                        
                                    <JumboTextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    
                                </Div>
                                
                                <LoadingButton
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >
                                    Enviar
                                </LoadingButton>

                            </Form>
                        )}
                    </Formik>
                    <Typography textAlign={"center"} variant={"body1"} mb={1}>
                        Não lembra do seu email?
                        <Link
                            underline="none" 
                            href="#"
                            >
                                Entre em contato com o suporte
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
        </Div>
    );
};

export default ForgotPassword;
