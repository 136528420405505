import React, {useCallback, useState} from 'react';
import {List} from "@mui/material";
import { useParams } from "react-router-dom";
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ListItemVolunteerInterested from './ListItemVolunteerInterested';

const ListRowVolunteerInterested = ({ data , setAddStudents, updateStudentList, classStudents, status,setStatus, setUsername, setSearchTerm}) => {
    const { id } = useParams()
    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const [itemsList, setItemsList] = useState(data);
    const includeStudentToClass = useCallback((record) => {
        try {
            ApiService.get(`/pessoa-voluntariada/${record.pessoa_id}`)
            .then((response) => {
                console.log(response.data)
                setStatus(true)
                setUsername(response.data.user)
                setSearchTerm(null)
                //toast('success', 'Aluno adicionado com sucesso');
               
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }

    }, [itemsList, status]);

    const usersWithFlag = itemsList.map(user => {
        const hasCourseVolunteer = user.Curso_Historico?.some(curso => curso.Turma.Curso.curso_id === 13);
        return { ...user, curso_de_voluntario: hasCourseVolunteer };
    });

    return (
            <List disablePadding>
                {
                    usersWithFlag.map((item, index) => (
                        <ListItemVolunteerInterested item={item} key={index} includeStudentToClass={includeStudentToClass} />
                    ))
                }
            </List>
    );
};

export default ListRowVolunteerInterested;
