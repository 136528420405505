import React, { useCallback, useState, useContext} from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Zoom, Grid} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import { Link, useNavigate } from 'react-router-dom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { useLocation } from 'react-router-dom'


const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));


export const LinkStyle = styled(Link)`
    padding: 4px 10px;
    background: #7352C7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`
const None = styled(Div) `
    @media(max-width: 1280px) {
        display: none;
    }
    
`

const UserItem = ({ item ,setUsers, users }) => {
    const location = useLocation();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const modalAlert = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                deleteUser(id);
            }
        });
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
      }

    const deleteUser = useCallback(async (id) => {
        const updatedUsersArray = removeById(users, id)
        setUsers(updatedUsersArray);

        try {
            ApiService.delete(`/pessoa/${id}`)
            .then((response) => {
                toast('success', 'Removido com sucesso');
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, []);

    const maxLengthText = 20;

    const { hasPermission } = useContext(PermissionContext);
    const navigate = useNavigate();
   /*  console.log('item.pessoa_id', item.pessoa_id)
    console.log('item.email', item.email) */
    return (
        <Card sx={{mb: 1}}>
            <Accordion square sx={{borderRadius: 2}}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Grid container spacing={2} alignContent={'center'}>
                        {/* <Grid item lg={1} md={2} xs={12} sx={{flexShrink: 0, px: 1}}>
                            <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={item.imagem_perfil ? `${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}` : '#'} variant="rounded"/>
                        </Grid> */}
                        <Grid item lg={4} md={4} xs={8} sx={{flexShrink: 0, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Nome
                            </Typography>
                            <Typography variant={"body1"}>{item.nome}</Typography>
                        </Grid>
                        <Grid item lg={3} md={3} xs={4} sx={{flexShrink: 0, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Email
                            </Typography>
                            {item.email &&
                                <Tooltip title={item.email} arrow TransitionComponent={Zoom}>
                                    <Typography variant={"body1"}>
                                        {TextService.limitText(item.email, maxLengthText)}
                                    </Typography>
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item lg={2} md={2} xs={3} sx={{flexShrink: 0, px: 1, display : 'none'}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Celular
                            </Typography>
                            <Typography variant={"body1"}>{item.celular}</Typography>
                        </Grid>
                        <Grid item lg={3} md={3} xs={4} sx={{flexShrink: 0, px: 1}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                CPF
                            </Typography>
                            <Typography variant={"body1"}>{item.cpf}</Typography>
                        </Grid>
                        <Grid item lg={2} md={2} xs={4} sx={{flexShrink: 0, px: 1, display: 'none'}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Data de nascimento
                            </Typography>
                            <Typography variant={"body1"}>{formatDate(item.data_nascimento)}</Typography>
                        </Grid>
                        <Grid item lg={1} md={2} xs={3} sx={{flexShrink: 0, px: 1, display : 'none'}}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Sexo
                            </Typography>
                            <Typography variant={"body1"}>{capitalize(item.genero)}</Typography>
                        </Grid>
                        <Grid item lg={2} md={2} xs={4} sx={{ ml: 'auto', flexDirection: 'row', display:'flex'}}>
                                <Item sx={{ml: 'auto'}}>
                                    <LinkStyle to={`/app/editar-aluno/${item.pessoa_id}`} state={{ backUrl: location.pathname }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                        <VisibilityIcon />
                                    </LinkStyle>
                                </Item>


                                <Item>
                                    <Button 
                                        sx={{minWidth: 1}} 
                                        disableElevation 
                                        variant={"contained"} 
                                        size={"small"} 
                                        color={"secondary"}
                                        disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                                        onClick={() =>  modalAlert(item.pessoa_id)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Item>
                        </Grid>
                    </Grid>
                </AccordionSummary>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */




export default UserItem;
