import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, AccordionDetails, Card, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import {TIME} from "../../../utils/constants/time";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from "@mui/material/Stack";
import { PermissionContext } from 'app/contexts/PermissionContext';

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const CourseHistoryItem = ({ item , handleChange, handleClose}) => {
    const [expanded, setExpanded] = useState(false);
    const { hasPermission } = useContext(PermissionContext)

    const toggleAccordion = () => setExpanded(!expanded);
    

    const Swal = useSwalWrapper();

    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    function removeById(arr, id) {
        // const objWithIdIndex = arr.findIndex((obj) => obj.pessoa_id === id);
      
        // if (objWithIdIndex > -1) {
        //   arr.splice(objWithIdIndex, 1);
        // }
      
        // return arr;
      }

    const deleteHistory = useCallback(async (id) => {
        try {
            ApiService.delete(`/historico-cursos/${id}`)
            .then((response) => {
                toast('success', 'Removido com sucesso');
                handleClose();
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, []);
    
    const [confirmExclusion, setConfirmExclusion] = useState(false);

    return (
        <Card sx={{mb: 1}} style={{ display: 'flex', flexDirection: 'row' }}>
            
            <Accordion expanded={expanded} onChange={handleChange} square sx={{borderRadius: 2, width: '100%'}}>
                <AccordionSummary
                    expandIcon={ item.status == 'Reprovado' 
                        ? 
                        <ExpandMoreIcon
                            onClick={toggleAccordion}
                        /> 
                        : 
                        null
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Ano
                        </Typography>
                        <Typography variant={"body1"}>{item.ano}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Nome
                        </Typography>
                        <Typography variant={"body1"}>{item.nome}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Dia da semana
                        </Typography>
                        <Typography variant={"body1"}>{item.dia_da_semana}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Horário
                        </Typography>
                        <Typography variant={"body1"}>{item.horario}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Local
                        </Typography>
                        <Typography variant={"body1"}>{item.local}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Status
                        </Typography>
                        <Typography variant={"body1"}>{item.status}</Typography>
                    </Div>
                    {/* <Item sx={{ml: 'auto', display: {xs: 'none', sm: 'block'}}} disableElevation>
                        <Button sx={{minWidth: 1, zIndex: 1300}} disableElevation variant={"contained"} size={"small"} color={"secondary"}
                            onClick={() => sweetAlerts(item.curso_historico_id)}
                        >
                            <DeleteIcon />
                        </Button>
                    </Item> */}
                    

                </AccordionSummary>
                {item.status == 'Reprovado' && 
                    <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                        <Typography variant={"h5"}>Motivo de reprovação</Typography>
                        <Typography variant={"body1"} color={"text.secondary"}>
                            {item.motivo_reprovacao}
                        </Typography>
                    </AccordionDetails>
                }
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                    <Typography variant={"h5"}>Observação</Typography>
                    <Typography variant={"body1"} color={"text.secondary"}>
                        {item.observacao}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Item sx={{ml: 'auto', display: {xs: 'none', sm: 'flex'}, alignSelf: 'center', flexDirection: 'row', gap: 2}} disableElevation>
                {confirmExclusion && 
                    <Button disableElevation variant={"contained"} size={"small"} sx={{ width: '120px' }}
                        onClick={() => setConfirmExclusion(false)}
                    >
                            Cancelar
                    </Button>
                }
                <Button 
                    disableElevation 
                    variant={"contained"} 
                    size={"small"} 
                    color={"secondary"}
                    disabled={!hasPermission('Pessoas', 'deletar') ? true : false}
                    onClick={() => confirmExclusion ? deleteHistory(item.curso_historico_id) : setConfirmExclusion(true)}
                >
                    {confirmExclusion
                    ? 'Confirmar exclusão'
                    : <DeleteIcon />
                    }
                    
                </Button>
            </Item>
        </Card>
    );
};
/* Todo item prop define */
export default CourseHistoryItem;
