import React from 'react';

const Home = () => {
    return (
        <div>
            {/* <h2>Sample blank page</h2>
            <p>This page is just to showcase the way you can add your own pages.</p>
            <p>Happy Coding!</p> */}
        </div>
    );
};

export default Home;