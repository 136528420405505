import React, { useState, useCallback, useEffect } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Paper, Select, Stack,Switch,TextField,Typography,useMediaQuery} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import { CheckBox } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";

import VolunteerInterestedHistoryItem from './VolunteerInterestedHistoryItem';
import VolunteerHistoryItem from './VolunteerHistoryItem';

const validationSchema = yup.object().shape({
    // status:
    // observacao:
    // turma_id:
    // turma_id_anterior:
    // pessoa_id:
});

const ModalVolunteerInterestedHistory = ({ handleCloseVolunteer }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const { id } = useParams()
    const [volunteerInterestedHistory, setVolunteerInterestedHistory] = useState([]);
    const [volunteerHistory, setVolunteerHistory] = useState([]);
    
    const getVolunteerHistory = useCallback(async () => {
        console.log(id)
        try {
            ApiService.get(`/pessoa/${id}`)
            .then((response) => {
                console.log('historico', response.data.user[0])
                setVolunteerInterestedHistory(response.data.user[0].Interesse_Voluntario);
                setVolunteerHistory(response.data.user[0].Voluntario)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      useEffect(() => {
        if(id) getVolunteerHistory();
      }, []);  

  return (
    <JumboContentLayout
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px', height: '500px'}}>
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>
                    Histórico de Voluntariados
                </Typography>
                <React.Fragment>
                <Typography variant={"h4"} style={{ textAlign: 'left', marginBottom: 30 }}>
                    Histórico de voluntário
                </Typography>
                    {volunteerHistory.length > 0 ?
                        volunteerHistory.map((data, key) => (
                            <VolunteerHistoryItem item={data} handleCloseVolunteer={handleCloseVolunteer}/>
                        ))
                    : 'Nenhum histórico encontrado'}
                
                <Typography variant={"h4"} style={{ textAlign: 'left', marginTop: 30 }}>
                    Histórico de interesse
                </Typography>
                    {volunteerInterestedHistory.length > 0 ?
                        volunteerInterestedHistory.map((data, key) => (
                            <VolunteerInterestedHistoryItem item={data} handleCloseVolunteer={handleCloseVolunteer}/>
                        ))
                    : 'Nenhum histórico encontrado'}
                    {/* {volunteerInterestedHistory.every(item => item.data_inicio !== '') && (
                        <p>Nenhum histórico encontrado</p>
                    )} */}
                </React.Fragment>
            </Box>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalVolunteerInterestedHistory