import React, { useEffect, useState } from 'react';
import {Card, CardContent, CircularProgress, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {getAssetPath} from "../../utils/appHelpers";
import { Link, useNavigate } from 'react-router-dom';
import { ApiService } from 'app/servicesTwo/ApiService';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";


const validationSchema = yup.object({
    senha: yup
    .string()
    .required('Preenchimento obrigatório')
    .min(8, ' A senha deve ter pelo menos 8 dígitos'),
    senhaConfirmacao: yup
    .string()
    .required('Preenchimento obrigatório')
    .oneOf([yup.ref('senha')], 'As senhas devem ser iguais')
});

const ResetPassword = () => {
    const [token, setToken] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [error, setError] = useState(null);
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    const Swal = useSwalWrapper();

    const navigate = useNavigate();
    

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        
        setToken(token)
        ApiService.get(`/reset-senha/${token}`)
        .then(() => {
            setTokenIsValid(true);
            setIsLoading(false);
        })
        .catch((error) => {
            setError(error.response.data.error);
            setIsLoading(false);
            setTokenIsValid(false);
        });
    
    }, []);

    if (isLoading) {
        return (
            <Div 
                sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100vh'
                }}
            >
                <CircularProgress />
            </Div>
        );
    }

    if (!tokenIsValid) {
        navigate('/esqueci-senha?linkInvalid=true', { state: { message: 'Seu link de redefinição de senha não é válido ou já foi usado.' } });
    }

    if (error) {
        navigate('/esqueci-senha?linkInvalid=true', { state: { message: error } });
    }

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });

        //  Redireciona se mensagem de retorno for de sucesso
        if(variant == 'success'){
            setTimeout(function () {
                navigate("/login")
            }, 4000);
        }
    };
   
    const resetPassword = async (senha, setSubmitting) => {
        await ApiService.post('/reset-senha', { token, senha })
        .then((res) => {
            console.log(res)
            toast('success', res.data.message)
        })
        .catch((err) => {
            console.log(err.response.data.error)
            toast('error', err.response.data.error)
        })

        setSubmitting(false)
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordConfirmation = (event) => {
        event.preventDefault();
    };

    return (
        <Div sx={{width: 720, maxWidth: '100%', margin: 'auto', p: 4}}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        backgroundImage: 'url(/images/logos/logo.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: {md: 320}
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h2"} color={"inherit"} fontWeight={500} mb={3}>Alterar
                                Senha</Typography>
                            <Typography variant={"body1"} mb={1} sx={{maxWidth: 270}}>
                                Para definir uma nova senha, basta preencher o campo ao lado com sua nova senha.
                            </Typography>
                            <Typography variant={"body1"} mb={1} sx={{maxWidth: 270}}>
                                Ao concluir a alteração da senha, clique em 'Salvar' para confirmar a alteração. Se precisar de ajuda ou tiver alguma dúvida, entre em contato com nosso suporte para obter assistência.
                            </Typography>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            senha: '',
                            senhaConfirmacao: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true)
                            resetPassword(data.senha, setSubmitting);
                        }}
                    >
                        {({isSubmitting, handleChange, values}) => (
                            <Form>
                                <Div
                                    sx={{
                                        display: 'flex',
                                        minWidth: 0,
                                        flex: 1,
                                        flexDirection: 'column',
                                        height: '100%'
                                    }}
                                >
                                    <Div sx={{mb: 2}}>
                                        <Div sx={{mt: 1, mb: 3}}>
                                            <JumboTextField
                                                fullWidth
                                                id="senha"
                                                type={showPassword ? 'text' : 'password'}
                                                name="senha"
                                                value={values.senha}
                                                InputProps={{ 
                                                    endAdornment: 
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                sx={{ background: '#ffffff'}}
                                                label="Senha"
                                            />
                                        </Div>
                                        <Div sx={{mt: 1, mb: 3}}>
                                            <JumboTextField
                                                fullWidth
                                                id="senhaConfirmacao"
                                                type={showPasswordConfirmation ? 'text' : 'password'}
                                                name="senhaConfirmacao"
                                                value={values.senhaConfirmacao}
                                                InputProps={{
                                                    endAdornment: 
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPasswordConfirmation}
                                                            onMouseDown={handleMouseDownPasswordConfirmation}
                                                            edge="end"
                                                            >
                                                    {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                sx={{ background: '#ffffff'}}
                                                label="Confirmar senha"
                                            />
                                        </Div>
                                        <LoadingButton
                                            color="info"
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{mb: 3}}
                                            loading={isSubmitting}
                                        >
                                            Salvar
                                        </LoadingButton>
                                    </Div>
                                    <Typography variant={"body1"} mt={'auto'}>
                                        Já possui uma conta? 
                                        <Link to={"/login"} >
                                            Faça login
                                        </Link> 
                                    </Typography>
                                </Div>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>

        </Div>
    );
};

export default ResetPassword;
