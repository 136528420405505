import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, AccordionDetails, Card, Tooltip, Typography, Grid} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import {DAYS_OF_WEEK} from "../../../utils/constants/daysOfWeek";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ModalTransferClass from "./ModalTransferClass";
import ModalChangeStatus from "./ModalChangeStatus";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import { TextService } from 'app/servicesTwo/TextService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { useLocation } from 'react-router-dom'

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const ClassStudentsListItem = ({ item ,setClassStudents, classStudents, courses, getCourse, coursesInClasses, classes }) => {
    const [open, setOpen] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [expand, setExpand] = useState(false);
    const location = useLocation();
    const { hasPermission } = useContext(PermissionContext);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseStatusModal = () => setOpenStatusModal(false);
    const toggleAcordion = () => setExpand(!expand);

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const modalAlert = (curso_historico_id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                removeStudentFromClass(curso_historico_id);
            }
        });
    };

    function removeById(arr, id) {
        console.log('arr', arr)
        console.log('id', id)
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_historico_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
      }

      const removeStudentFromClass = useCallback(async (curso_historico_id) => {
        try {
            ApiService.delete(`/turmas/aluno/${curso_historico_id}`,)
            .then((response) => {
                toast('success', 'Removido com sucesso');

                const updatedStudentsArray = removeById(classStudents, curso_historico_id)
                setClassStudents(updatedStudentsArray);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
    }, [classStudents, setClassStudents]);

    const maxLengthText = 20;

    return (
        <Card sx={{mb: 1}}>
            <Accordion square sx={{borderRadius: 2}} expanded={expand}>
                <AccordionSummary
                    /* expandIcon={ item.status == 'Reprovado' 
                        ? 
                        <ExpandMoreIcon 
                            onClick = {toggleAcordion}
                        /> 
                        : 
                        null
                    } */
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                   
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Grid container spacing={3} alignContent={'center'}>
                        <Grid item lg={1} md={2} xs={2} sx={{display: 'none'}}>
                            <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`} variant="rounded"/>
                        </Grid>
                        <Grid item lg={4} md={4} xs={6}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Nome
                            </Typography>
                            <Typography variant={"body1"}>{item.nome}</Typography>
                        </Grid>
                        <Grid item lg={3} md={3} xs={12} sx={{ display: {xs: 'none', md: 'none', lg: 'none'} }}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Email
                            </Typography>

                            {item.email?.length < maxLengthText 
                                ? 
                                (
                                    <Typography variant={"body1"}>
                                        {item.email}
                                    </Typography>
                                )
                                :
                                (
                                    <Tooltip title={item?.email} arrow TransitionComponent={Zoom}>
                                        <Typography variant={"body1"}>
                                            {TextService.limitText(item.email, maxLengthText)}
                                        </Typography>
                                    </Tooltip>
                                )

                            }
                        </Grid>
                        <Grid item lg={3} md={3} xs={2} sx={{ display: {xs: 'none', md: 'none', lg: 'block'} }}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                CPF
                            </Typography>
                            <Typography variant={"body1"}>{item?.cpf}</Typography>
                        </Grid>
                        <Grid item lg={1} md={2} xs={4}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Visualizar
                            </Typography>

                            {/* <Button sx={{minWidth: 1}} disableElevation variant={"contained"} size={"small"} color={"primary"}
                                href={`/app/editar-aluno/${item.pessoa_id}`}
                            >
                                <OpenInNewIcon />
                            </Button> */}
                            {/* to={{ pathname: '/foo', query: { the: 'query' } }}/> */}
                            <LinkStyle to={`/app/editar-aluno/${item.pessoa_id}`} state={{ backUrl: location.pathname,  disable: true }}>
                                <VisibilityIcon />
                            </LinkStyle>
                        </Grid>
                        <Grid item lg={2} md={2} xs={4}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Status
                            </Typography>
                            <Button sx={{minWidth: 1}} disableElevation variant={"contained"} size={"small"} color={"primary"}
                                onClick={() => setOpenStatusModal(true)}
                            >
                                {item.status ?? '-'}
                            </Button>
                        </Grid>
                        <Grid item lg={1} md={1} xs={4}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Transferir
                            </Typography>
                            <Button 
                                sx={{minWidth: 1}} 
                                disableElevation 
                                variant={"contained"} 
                                size={"small"} 
                                color={"primary"}
                                disabled={!hasPermission('Turmas', 'editar')}
                                onClick={handleOpen}
                                //   href={`/app/editar-aluno/${item.pessoa_id}`}
                            >
                                <SwapHorizIcon />
                            </Button>
                        </Grid>
                        <Grid item lg={1} md={1} xs={4}>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                                Excluir
                            </Typography>
                            <Button 
                                sx={{minWidth: 1}} 
                                disableElevation 
                                variant={"contained"} 
                                size={"small"} 
                                color={"secondary"}
                                disabled={!hasPermission('Turmas', 'deletar')}
                                onClick={() => modalAlert(item.curso_historico_id)}
                            >
                                <DeleteIcon />
                            </Button>
                        </Grid>
                    </Grid>
                
                </AccordionSummary>
                {/* {item.status == 'Reprovado' && 
                    <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                        <Typography variant={"h5"}>Motivo de reprovação</Typography>
                        <Typography variant={"body1"} color={"text.secondary"}>
                            {item.motivo_reprovacao}
                        </Typography>
                    </AccordionDetails>
                }
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 7.5)}}>
                    <Typography variant={"h5"}>Observação</Typography>
                    <Typography variant={"body1"} color={"text.secondary"}>
                        {item.observacao}
                    </Typography>
                </AccordionDetails> */}
            </Accordion>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        <ModalTransferClass data={item} getCourse={getCourse} courses={courses} handleClose={handleClose} classStudents={classStudents} setClassStudents={setClassStudents} coursesInClasses={coursesInClasses} classes={classes}/>
                    </Div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openStatusModal}
                onClose={handleCloseStatusModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openStatusModal}>
                    <Div sx={style}>
                        <ModalChangeStatus data={item} curso_historico_id={item.curso_historico_id} handleClose={handleCloseStatusModal} classStudents={classStudents} setClassStudents={setClassStudents}/>
                    </Div>
                </Fade>
            </Modal>
        </Card>
    );
};
/* Todo item prop define */
export default ClassStudentsListItem;
