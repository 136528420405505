import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Paper, Stack,Switch,Typography,useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Box, Input} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { updateInputValue, disableInputValue } from "../../../utils/appHelpers";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ModalCourseHistory from "../course_histories/ModalCourseHistory";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import axios from 'axios';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { COUNTRIES } from '../../../utils/constants/countries'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { cpf } from 'cpf-cnpj-validator'; 
import { PermissionContext } from 'app/contexts/PermissionContext';
import ModalVolunteerInterestedHistory from 'app/components/VolunteersInterested/Modals/ModalVolunteerInterestedHistory';
import InfosAboutCreateAndUpdate from 'app/components/InfosAboutCreateAndUpdate/InfosAboutCreateAndUpdate';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
    p: 4,
};

const UserForm = () => {
    const [open, setOpen] = useState(false);
    const [openVolunteer, setOpenVolunteer] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenVolunteer = () => setOpenVolunteer(true);
    const handleCloseVolunteer = () => setOpenVolunteer(false);

    const { hasPermission } = useContext(PermissionContext);

    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();

    const small = window.matchMedia('(max-width:1000px)');

    const Swal = useSwalWrapper();

    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const initialValues = {
        nome: "",
        data_nascimento: "",
        genero: "",
        nacionalidade: "",
        cpf: "",
        rg: "",
        DDI:"",
        DDD:"",
        telefone: "05511",
        DDI_celular: "",
        DDD_celular: "",
        celular: "05511",
        aceita_contato_whatsapp: "sim",
        documento_internacional: "",
        estado_civil: "",
        tem_filhos: "",
        qtde_filhos: "",
        email: "",
        escolaridade: "",
        religiao: "",
        formacao_principal: "",
        ocupacao: "",
        ocupacao_natureza: "",
        profissao: "",
        habilidade_especifica: "",
        obs_saude: "",
        conhece_espiristimo: false,
        cursos_fora_feesp: "",
        obs_voluntariado: "",
        obs_gerais: "",
        imagem_perfil: "",
        pais: "BR",
        bairro: "",
        cidade: "",
        estado: "",
        uf: "",
        numero: "",
        complemento: "",
        CEP: "",
        codigo_postal: "",
        senha: "",
        reset_password_token: "teste_token",
        trabalhos_voluntarios: false,
        infos_usuario: false,
        quais_cuidados: false,
        celular_emergencia: "05511",
        celular_confirmacao: "",
        email_confirmacao: "",
    }

    const { id } = useParams()
    const [user, setUser] = useState(initialValues);
    const [whoCreated, setWhoCreated] = useState([]);
    const [whoUpdated, setWhoUpdated] = useState([]);
    const [userImage, setUserImage] = useState(null);
    const [userLanguages, setUserLanguages] = useState([{ idiomas_id: "", nivel_idioma: "" }]);
    const [deleteLanguages, setDeleteLanguages] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dataNascimentoObrigatorio, setdataNascimentoObrigatorio] = useState(false);
    const [emailObrigatorio, setEmailObrigatorio] = useState(false);
    const [celularObrigatorio, setCelularObrigatorio] = useState(false);
    const [cpfObrigatorio, setCpfObrigatorio] = useState(false);

    const [errorsFromApi, setErrorsFromApi] = useState('');

    const canCreate = hasPermission('Pessoas', 'criar');
    const canEdit = hasPermission('Pessoas', 'editar');
    let { state } = useLocation();
    const navigate = useNavigate();
    const isDisabled = state && state.disable ? true : (id ? !canEdit : !canCreate)

    const validationSchema = yup.object().shape({
        /* email: yup
            .string('Insira seu email')
            .email('Insira um email válido')
            .when(['cpf', 'rg'], {
                is: (cpf, rg) => !cpf && !rg,
                then: yup.string().required('Preenchimento obrigatório')
            })
            ,
        email_confirmacao: yup
            .string('Insira a confirmação do email')
            .email('Insira um email válido')
            .test('emails-match', 'Os emails devem ser iguais', function(value){
                return this.parent.email === value
            })
            , */
        email: !emailObrigatorio ? yup.string()
            .email('Endereço de email inválido')
            .nullable()
            .required('Preenchimento obrigatório') 
            : yup.string().nullable(),
        email_confirmacao: !emailObrigatorio ? yup.string()
            .oneOf([yup.ref('email'), null], 'Os emails devem ser iguais')
            .required('Preenchimento obrigatório')
            .nullable()
            : yup.string().nullable(),
        cpf:!cpfObrigatorio ? yup
            .string()
            .test({ 
                message: 'CPF inválido',
                test: (value) => cpf.isValid(value)
            })
            .when(['email', 'rg'], {
                is: (email, rg) => !email && !rg,
                then: yup.string().required('Preenchimento obrigatório').nullable()
            }) 
            : yup.string().nullable(),
        /* rg: yup
            .string()
            .max(11, 'Deve ter no máximo 11 digitos')
            .when(['cpf', 'email'], {
                is: (cpf, email) => !cpf && !email,
                then: yup.string().required('Preenchimento obrigatório')
            })
            , */
        nome: yup
            .string('Insira seu nome')
            .required('Preenchimento obrigatório'),
        data_nascimento: !dataNascimentoObrigatorio ? yup
            .date()
            .max(new Date(), 'Não é possivel incluir uma data futura')
            .required('Preenchimento obrigatório').nullable() :  yup.string().nullable(),
        celular: !celularObrigatorio ? yup
            .string()
            .required('Preenchimento obrigatório')
            .nullable()
            .transform(value => value?.replace(/[^\d]/g, ''))
            .min(14, "Preencha o número completo")
            .max(14, "Preencha o número completo")
            : yup.string().nullable(),
        celular_confirmacao: !celularObrigatorio ? yup
            .string()
            .required('Preenchimento obrigatório')
            .nullable()
            .transform(value => value?.replace(/[^\d]/g, ''))
            .test('cell-match', 'Os números do celular devem ser iguais', function(value){
                return this.parent.celular === value
            })
            : yup.string().nullable(),
    
            /* celular: yup.string().required('Preenchimento obrigatório'),
            celular_confirmacao: yup.string()
            .oneOf([yup.ref('celular'), null], 'Os números do celular devem ser iguais'), */
        //CEP: yup
            //.string()
            //.when(['pais'], {
                //is: (pais) => pais === 'BR',
                //then: yup.string().required('Preenchimento obrigatório')
            //})
            // .required('Preenchimento obrigatório')
            //.matches(`[0-9]{5}-[0-9]{3}`, "Digite o CEP completo"),
        //bairro: yup
            //.string()
            //.when(['pais'], {
                //is: (pais) => pais === 'BR',
                //then: yup.string().required('Preenchimento obrigatório')
            //}),
            // .required('Preenchimento obrigatório'),
        // cidade: yup
        //     .string()
        //     .required('Preenchimento obrigatório'),
        //pais: yup
            //.string()
            //.required('Preenchimento obrigatório'),
        }, [['email', 'cpf'], ['email', 'rg'], ['cpf','rg']]);

    const toast = (variant, message, type = false) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });

        //  Redireciona se mensagem de retorno for de sucesso
        if(variant == 'success' && (!userImage || (userImage && type))){
            setTimeout(function(){
                navigate("/app/listar-usuarios")
            }, 2000)
        }
    };
console.log(user)
    const getUser = useCallback(async () => {
        try {
            await ApiService.get(`/pessoa/${id}`)
            .then((response) => {
              setUser(response.data.user[0]);

              if(response.data.user[0].pais == null || response.data.user[0].pais == '' || response.data.user[0].pais == 'brasil') {
                setUser({...response.data.user[0], pais: 'BR'})
              }

              if(response.data.user[0].data_nascimento == null || response.data.user[0].data_nascimento == '') {
                setdataNascimentoObrigatorio(true)
              }
              if(response.data.user[0].email == null || response.data.user[0].email == '') {
                setEmailObrigatorio(true)
              }
              if(response.data.user[0].celular == null || response.data.user[0].celular == '') {
                setCelularObrigatorio(true)
              }
              if(response.data.user[0].cpf == null || response.data.user[0].cpf == '') {
                setCpfObrigatorio(true)
              }

              if(response.data.userLanguages.length > 0){
                setUserLanguages(response.data.userLanguages)
              }
              if(response.data.quemCriou !== null) {
                setWhoCreated(response.data.whoCreated?.nome)
              }
              if(response.data.quemAtualizou !== null) {
                setWhoUpdated(response.data.whoUpdated?.nome)
              }
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
    }, []);

    useEffect(() => {
        if(id) getUser();
    }, []);

    const handleChangeImage = (e) => {
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        setUserImage(e.target.files[0])
    }

    const handleSubmit = async (values, { setSubmitting, setFieldError, setStatus }) => {
        var personID = id;
        let data = new FormData()
        data.append("imagem_perfil", userImage)
        let userLanguagesFiltered = userLanguages.filter((x) => !x.idiomas_id == "" && !x.nivel_idioma == "")

        if(id){
           await ApiService.put(`/pessoa/${id}`, {
                values,
                userLanguages: userLanguagesFiltered,
                deleteLanguages
               })
               .then(() => {
                    toast('success', 'Atualizado com sucesso')
                })
               .catch((error) => {
                    const target = error.response.data.error;
                    console.log(error.response.data)
                    setErrorsFromApi(target)
                    //toast('error', target)
                    //let message = 'Ocorreu um erro'
                    
                    if(target.toLowerCase().includes('cpf')){
                        toast('error', 'Digite um CPF único')
                        setFieldError('cpf', 'Digite um CPF único');
                    } else if(target.toLowerCase().includes('rg')){
                        toast('error', 'Digite um RG único')
                        setFieldError('rg', 'Digite um RG único');
                    } else if(target.toLowerCase().includes('email')){
                        toast('error', 'Digite um email único')
                        setFieldError('email', 'Digite um email único');
                    }
                    //toast('error', message)

                   if (error.response.data) {
                        console.log(error.response.data.error);
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('error', error.message);
                    }
               })
        } else {
            await ApiService.post('/pessoa', {
                values,
                userLanguages: userLanguagesFiltered
               })
              .then((response) => {
                personID = response.data.pessoa_id
                toast('success', 'Criado com sucesso')
              })
              .catch((error) => {
                const target = error.response.data.error;
                console.log(error.response.data)
                setErrorsFromApi(target)
                //toast('error', target)
                //let message = 'Ocorreu um erro'
                
                if(target.toLowerCase().includes('cpf')){
                    toast('error', 'Digite um CPF único')
                    setFieldError('cpf', 'Digite um CPF único');
                } else if(target.toLowerCase().includes('rg')){
                    toast('error', 'Digite um RG único')
                    setFieldError('rg', 'Digite um RG único');
                } else if(target.toLowerCase().includes('email')){
                    toast('error', 'Digite um email único')
                    setFieldError('email', 'Digite um email único');
                }
               

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
              })
        }

        if(userImage) {
            ApiService.put(`/pessoa/atualizar-imagem/${personID}`, data)
              .then(() => {
                toast('success', 'Imagem atualizada com sucesso', true)
              })
              .catch((error) => {
                toast('error', 'Ocorreu um erro ao atualizar imagem', true)

                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
              })
            
        }
        setSubmitting(false);
    } 
    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...userLanguages];
        list[index][name] = value;
        setUserLanguages(list);
    };

    const handleRemoveClick = index => {
        const list = [...userLanguages];

        const language = list[index];
        setDeleteLanguages([...deleteLanguages, language])

        list.splice(index, 1);
        setUserLanguages(list);
    };

    const handleAddClick = () => {
        setUserLanguages([...userLanguages, { idiomas_id: "", nivel_idioma: "" }]);
    };
    
    const checkCep = async (e, setFieldValue) => {
        setIsLoading(true);
        const {value} = e.target;
        console.log(value)
        const cep = value.replace(/\D/g, '');
        await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
            console.log(res.data)
            setFieldValue('endereco', res.data.logradouro)
            // setFieldValue('complemento', res.data.complemento)
            setFieldValue('bairro', res.data.bairro)
            setFieldValue('cidade', res.data.localidade)
            setFieldValue('uf', res.data.uf)
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            console.log(err)
        })
    }

    const errorsHandler = (errors) => {
        
        if(Object.keys(errors).length != 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if(errors.cpf) {
                //toast('error', errors.cpf)
            } else if(errors.data_nascimento) {
                //toast('error', errors.data_nascimento)
            } else if(errors.email) {
               // toast('error', errors.email)
            } else if(errors.email_confirmacao) {
               // toast('error', errors.email_confirmacao)
            } else if(errors.celular) {
               // toast('error', errors.celular)
            } else if(errors.celular_confirmacao) {
               // toast('error', errors.celular_confirmacao)
            }  else {
                //toast('error', errors.nome)
            }
        } else {
            return null
        }
    }

  return (
    <JumboContentLayout
        header={
            <HeaderBreadcrumbs
                id={id}
                title={"Pessoas"}
                subtitle={id ? "Editar" : "Cadastro de Pessoas"}
                titleUrl={"/app/listar-usuarios"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        
        <Box display='flex' justifyContent='space-between' gap='1rem' marginBottom='1rem'>
            <Box width={ id ? '60%' : 'auto'}>
                <Paper elevation={1} sx={{p: '10px'}}>
                    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Typography variant='h6'>
                            Informação não disponivel
                        </Typography>
                        <Box>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="data_nascimento"
                                        size='small'
                                        checked={dataNascimentoObrigatorio}
                                        onChange={(e) => setdataNascimentoObrigatorio(e.target.checked)}
                                    />
                                }
                                label="Data de Nascimento"
                                labelPlacement="end"
                                
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="email"
                                        size='small'
                                        checked={emailObrigatorio}
                                        onChange={(e) => setEmailObrigatorio(e.target.checked)}
                                    />
                                }
                                label="Email"
                                labelPlacement="end"
                                
                            />
                            <FormControlLabel
                                //name={updateInputValue(user, values, 'teve_vivencia_pratica')}
                                control={
                                    <Checkbox
                                        name="celular"
                                        size='small'
                                        checked={celularObrigatorio}
                                        onChange={(e) => setCelularObrigatorio(e.target.checked)}
                                    />
                                }
                                label="Celular"
                                labelPlacement="end"
                                
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="cpf"
                                        size='small'
                                        checked={cpfObrigatorio}
                                        onChange={(e) => setCpfObrigatorio(e.target.checked)}
                                    />
                                }
                                label="CPF"
                                labelPlacement="end"
                            />
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
           
        <Paper sx={{p: '15px', background: '#f9f9f9'}}>
            {state &&
                <Button
                    color="success"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                    onClick={() => navigate(state.backUrl)}
                >
                    Voltar
                </Button>
            }
            <Formik
                initialValues={user}
                // validationSchema={null}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={false}
                //validateOnBlur={true}
                onSubmit={handleSubmit}
            >
                {({values, isSubmitting, handleChange, setFieldValue, touched, errors, setTouched, setFieldTouched}) => (

                    <Form style={{width: '100%'}} noValidate autoComplete='off'>
                        <Grid container alignContent={'center'}>
                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item lg={6} xs={12} >
                                    <JumboTextField
                                        fullWidth
                                        name="nome"
                                        label="Nome"
                                        value={updateInputValue(user, values, 'nome')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="data_nascimento"
                                        type="date"
                                        label="Data de Nascimento"
                                        value={updateInputValue(user, values, 'data_nascimento')}
                                        sx={{ background: '#ffffff'}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={3} xs={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    {previewImage && 
                                        <img src={previewImage} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                    }
                                    {values.imagem_perfil && !previewImage && 
                                        <img src={`${process.env.REACT_APP_API_KEY}/images/${values.imagem_perfil}`} alt="img" style={{ width: 50, height: 50, borderRadius: 50, marginRight: 10 }}/>
                                    }
                                    <Button 
                                        variant="contained" 
                                        component="label" 
                                        color="info" sx={{ height: '53.13px',fontSize: {xs: '0.7rem'} }}
                                        disabled={isDisabled}
                                        >
                                        Adicionar foto
                                        <input type="file" hidden onChange={handleChangeImage}/>
                                    </Button>
                                    
                                </Grid>
                                
                                <Grid item lg={3} xs={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="nacionalidade"
                                        label="Nacionalidade"
                                        value={updateInputValue(user, values, 'nacionalidade')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={updateInputValue(user, values, 'cpf')}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        disableUnderline={isDisabled}
                                    >
                                        {(inputProps) => 
                                        <JumboTextField
                                        {...inputProps}
                                            id='cpf'
                                            fullWidth
                                            name="cpf"
                                            label="CPF"
                                            type="text"
                                            sx={{ background: '#ffffff'}}
                                            disabled={inputProps.disableUnderline}
                                            
                                            />
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                
                                    <JumboTextField
                                        fullWidth
                                        name="rg"
                                        label="RG"
                                        value={updateInputValue(user, values, 'rg')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                            
                                </Grid>
                                <Grid item lg={3} xs={6} >
                                    <JumboTextField
                                        fullWidth
                                        name="obs_gerais"
                                        label="Outros"
                                        value={updateInputValue(user, values, 'obs_gerais')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={4} xs={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="genero">Gênero</InputLabel>
                                        <JumboSelectField
                                            labelId="genero"
                                            id="genero"
                                            name="genero"
                                            value={updateInputValue(user, values, 'genero')}
                                            label="Gênero"
                                            sx={{ background: '#ffffff'}}
                                            disabled={isDisabled}
                                        >
                                            <MenuItem value={'masculino'}>Masculino</MenuItem>
                                            <MenuItem value={'feminino'}>Feminino</MenuItem>
                                            <MenuItem value={'outro'}>Outro</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid> 
                                <Grid item lg={4} xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="estado_civil">Estado Civil</InputLabel>
                                        <JumboSelectField
                                            labelId="estado_civil"
                                            id="estado_civil"
                                            name="estado_civil"
                                            value={updateInputValue(user, values, 'estado_civil')}
                                            label="Estado Civil"
                                            sx={{ background: '#ffffff'}}
                                            disabled={isDisabled}
                                        >
                                            <MenuItem value={'casado'}>Casado(a)</MenuItem>
                                            <MenuItem value={'solteiro'}>Solteiro(a)</MenuItem>
                                            <MenuItem value={'divorciado'}>Divorciado(a)</MenuItem>
                                            <MenuItem value={'uniao_estavel'}>União Estável</MenuItem>
                                            <MenuItem value={'outro'}>Outro</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2} xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tem_filhos">Tem filhos?</InputLabel>
                                        <JumboSelectField
                                            labelId="tem_filhos"
                                            id="tem_filhos"
                                            name="tem_filhos"
                                            value={updateInputValue(user, values, 'tem_filhos')}
                                            label="Tem filhos?"
                                            sx={{ background: '#ffffff'}}
                                            disabled={isDisabled}
                                        >
                                            <MenuItem value={'semFilhos'}>Não</MenuItem>
                                            <MenuItem value={'comFilhos'}>Sim</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={2} xs={6} >
                                    <JumboTextField
                                        fullWidth
                                        name="qtde_filhos"
                                        label="Quantos?"
                                        value={
                                            values.tem_filhos == 'comFilhos' 
                                            ? updateInputValue(user, values, 'qtde_filhos') 
                                            : 0}
                                        disabled={
                                            /* user &&(
                                            user.tem_filhos == values.tem_filhos)
                                            ? ['semFilhos', ''].includes(user.tem_filhos) 
                                            : ['semFilhos', ''].includes(values.tem_filhos)  */
                                            isDisabled
                                        }
                                        sx={{ background: '#ffffff'}}
                                        
                                    />
                                </Grid>  
                                <Grid item lg={3} xs={12} >
                                    <InputMask
                                        mask="+999(99)99999-9999"
                                        maskPlaceholder={null}
                                        error={touched.celular ? errors.celular : undefined}
                                        touched={touched.celular}
                                        value={updateInputValue(user, values, 'celular')}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        disabledUnderline={isDisabled}
                                    >
                                        {(inputProps) =>
                                            <JumboTextField
                                                {...inputProps}
                                                fullWidth
                                                name="celular"
                                                label="Celular (DDI, DDD e número)"
                                                sx={{ background: '#ffffff'}}
                                                disabled={inputProps.disabledUnderline}
                                            /> 
                                        }
                                    </InputMask>
                                    
                                </Grid>
                                <Grid item lg={3} xs={12} >
                                    <InputMask              
                                        mask="+999(99)99999-9999"
                                        maskPlaceholder={null}
                                        value={updateInputValue(user, values, 'celular_confirmacao')}
                                        onChange={handleChange}
                                        onPaste={(e) => e.preventDefault()}
                                        error={touched.celular_confirmacao ? errors.celular_confirmacao : undefined}
                                        touched={touched.celular_confirmacao}
                                        // onBlur={() => setTouched({'celular_confirmacao': true})}
                                        onBlur={() => setFieldTouched('celular_confirmacao', true)}
                                        disabled={isDisabled}
                                        disabledUnderline={isDisabled}
                                    >
                                        {(inputProps) =>
                                            <JumboTextField
                                                {...inputProps}
                                                fullWidth
                                                name="celular_confirmacao"
                                                label="Confirmar celular (DDI, DDD e número)"
                                                sx={{ background: '#ffffff'}}
                                                disabled={inputProps.disabledUnderline}
                                            /> 
                                        }
                                    </InputMask>
                                    
                                </Grid>
                                <Grid item lg={3} xs={12} >
                                    <InputMask
                                        mask="+999(99)9999-9999"
                                        value={updateInputValue(user, values, 'telefone')}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        disabledUnderline={isDisabled}
                                        >
                                            {(inputProps) =>
                                                <JumboTextField
                                                    {...inputProps}
                                                    fullWidth
                                                    name="telefone"
                                                    label="Telefone residencial (DDI, DDD e número)"
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={inputProps.disabledUnderline}
                                                />
                                            }
                                    </InputMask>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={updateInputValue(user, values, 'email')}
                                        sx={{ background: '#ffffff'}}
                                        error={touched.email ? errors.email : undefined}
                                        touched={+touched.email}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={6} xs={12} >
                                    <JumboTextField
                                        fullWidth
                                        name="email_confirmacao"
                                        label="Confirmar email"
                                        type="email"
                                        value={updateInputValue(user, values, 'email_confirmacao')}
                                        sx={{ background: '#ffffff'}}
                                        onPaste={e => e.preventDefault()}
                                        onChange={handleChange}
                                        error={touched.email_confirmacao ? errors.email_confirmacao : undefined}
                                        touched={+touched.email_confirmacao}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={4} xs={6} >
                                    
                                    <FormControl fullWidth>
                                        <Select 
                                            options={COUNTRIES} 
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(pais) =>
                                                setFieldValue('pais', pais.value)
                                            }
                                            value = {
                                                COUNTRIES.filter(option => 
                                                    option.value === values.pais)
                                            }
                                            placeholder="País"
                                            isDisabled={isDisabled}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={8} xs={6}>
                                    <Typography variant="h6" component="h6">
                                        Escolha no campo de seleção ao lado o País em que você mora atualmente
                                    </Typography>
                                </Grid>
                                {/* <div style={{ marginTop: 20 }}>
                                    {JSON.stringify(values.pais)}
                                </div> */}

                                {values.pais === 'BR' 
                                    ? (
                                        <>
                                            <Grid item lg={2} xs={6} >
                                                <InputMask
                                                    mask="99999-999"
                                                    value={updateInputValue(user, values, 'CEP')}
                                                    onChange={handleChange}
                                                    onBlur={(e) => checkCep(e, setFieldValue)}
                                                    disabled={isDisabled}
                                                    disabledUnderline={isDisabled}
                                                    >
                                                    {(inputProps) => 
                                                        <JumboTextField
                                                            {...inputProps}
                                                            fullWidth
                                                            name="CEP"
                                                            label="CEP"
                                                            sx={{ background: '#ffffff'}}
                                                            disabled={inputProps.disabledUnderline}
                                                        />
                                                    }
                                                </InputMask>
                                            </Grid>
                                            <Grid item lg={1} xs={1}>
                                                {isLoading && <CircularProgress style={{ alignSelf: 'center' }}/>}
                                            </Grid>
                                            <Grid item lg={7} xs={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="endereco"
                                                    label="Endereco"
                                                    value={updateInputValue(user, values, 'endereco')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item lg={2} xs={4}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="numero"
                                                    label="numero"
                                                    value={updateInputValue(user, values, 'numero')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item lg={3} xs={8}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="complemento"
                                                    label="Complemento"
                                                    value={updateInputValue(user, values, 'complemento')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item lg={3} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="bairro"
                                                    label="Bairro"
                                                    value={updateInputValue(user, values, 'bairro')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item lg={4} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="cidade"
                                                    label="Cidade"
                                                    value={updateInputValue(user, values, 'cidade')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            <Grid item lg={2} xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="uf">UF</InputLabel>
                                                    <JumboSelectField
                                                        labelId="uf"
                                                        id="uf"
                                                        name="uf"
                                                        value={updateInputValue(user, values, 'uf')}
                                                        label="Uf"
                                                        sx={{ background: '#ffffff'}}
                                                        disabled={isDisabled}
                                                    >
                                                        <MenuItem value={'AC'}>Acre</MenuItem>
                                                        <MenuItem value={'AL'}>Alagoas</MenuItem>
                                                        <MenuItem value={'AP'}>Amapá</MenuItem>
                                                        <MenuItem value={'AM'}>Amazonas</MenuItem>
                                                        <MenuItem value={'BA'}>Bahia</MenuItem>
                                                        <MenuItem value={'CE'}>Ceará</MenuItem>
                                                        <MenuItem value={'DF'}>Distrito Federal</MenuItem>
                                                        <MenuItem value={'ES'}>Espírito Santo</MenuItem>
                                                        <MenuItem value={'GO'}>Goiás</MenuItem>
                                                        <MenuItem value={'MA'}>Maranhão</MenuItem>
                                                        <MenuItem value={'MT'}>Mato Grosso</MenuItem>
                                                        <MenuItem value={'MS'}>Mato Grosso do Sul</MenuItem>
                                                        <MenuItem value={'MG'}>Minas Gerais</MenuItem>
                                                        <MenuItem value={'PA'}>Pará</MenuItem>
                                                        <MenuItem value={'PB'}>Paraíba</MenuItem>
                                                        <MenuItem value={'PR'}>Paraná</MenuItem>
                                                        <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                                        <MenuItem value={'PI'}>Piauí</MenuItem>
                                                        <MenuItem value={'RJ'}>Rio de Janeiro</MenuItem>
                                                        <MenuItem value={'RN'}>Rio Grande do Norte</MenuItem>
                                                        <MenuItem value={'RS'}>Rio Grande do Sul</MenuItem>
                                                        <MenuItem value={'RO'}>Rondônia</MenuItem>
                                                        <MenuItem value={'RR'}>Roraima</MenuItem>
                                                        <MenuItem value={'SC'}>Santa Catarina</MenuItem>
                                                        <MenuItem value={'SP'}>São Paulo</MenuItem>
                                                        <MenuItem value={'SE'}>Sergipe</MenuItem>
                                                        <MenuItem value={'TO'}>Tocantins</MenuItem>
                                                    
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>
                                            </>
                                    ) 
                                    : 
                                    (
                                        <> 
                                            <Grid item xs={12} >
                                                <JumboTextField
                                                    fullWidth
                                                    name="endereco"
                                                    label="Endereço"
                                                    value={updateInputValue(user, values, 'endereco')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                            </Grid> 
                                            <Grid item lg={4} xs={6} >  
                                                <JumboTextField
                                                    fullWidth
                                                    name="codigo_postal"
                                                    label="Codigo postal"
                                                    sx={{ background: '#ffffff'}}
                                                    value={updateInputValue(user, values, 'codigo_postal')}
                                                    disabled={isDisabled}
                                                />
                                            </Grid>
                                            
                                            <Grid item lg={4} xs={6}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="estado"
                                                    label="Estado"
                                                    value={updateInputValue(user, values, 'estado')}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                />
                                                
                                            </Grid>
                                        </>
                                    )
                                }

                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item lg={4} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="ocupacao_natureza">Natureza da ocupação</InputLabel>
                                        <JumboSelectField
                                            labelId="ocupacao_natureza"
                                            id="ocupacao_natureza"
                                            name="ocupacao_natureza"
                                            value={updateInputValue(user, values, 'ocupacao_natureza')}
                                            label="Natureza da ocupação"
                                            sx={{ background: '#ffffff'}}
                                            disabled={isDisabled}
                                        >
                                            <MenuItem value={'funcionario_publico'}>Funcionário Público</MenuItem>
                                            <MenuItem value={'funcionario_empresa_privada'}>Funcionário de empresa privada</MenuItem>
                                            <MenuItem value={'empresario'}>Empresário</MenuItem>
                                            <MenuItem value={'aposentado'}>Aposentado</MenuItem>
                                            <MenuItem value={'outro'}>Outro</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={8} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="ocupacao"
                                        label="Ocupação"
                                        multiline
                                        rows={4}
                                        value={updateInputValue(user, values, 'ocupacao')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>

                                <Grid item lg={4} xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="escolaridade">Nível de escolaridade</InputLabel>
                                        <JumboSelectField
                                            labelId="escolaridade"
                                            id="escolaridade"
                                            name="escolaridade"
                                            value={updateInputValue(user, values, 'escolaridade')}
                                            label="Nível de escolaridade"
                                            sx={{ background: '#ffffff'}}
                                            disabled={isDisabled}
                                        >
                                            <MenuItem value={'nao_estudei'}>Não estudei</MenuItem>
                                            <MenuItem value={'primeiro_grau'}>Primeiro grau</MenuItem>
                                            <MenuItem value={'segundo_grau'}>Segundo grau</MenuItem>
                                            <MenuItem value={'superior'}>Superior</MenuItem>
                                            <MenuItem value={'pos_graduado'}>Pós graduado</MenuItem>
                                            <MenuItem value={'mestrado'}>Mestrado</MenuItem>
                                            <MenuItem value={'doutorado'}>Doutorado</MenuItem>
                                            <MenuItem value={'pos_doutorado'}>Pós doutorado</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} xs={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="formacao_principal"
                                        label="Formação Principal"
                                        value={updateInputValue(user, values, 'formacao_principal')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="profissao"
                                        label="Profissão"
                                        value={updateInputValue(user, values, 'profissao')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="habilidade_especifica"
                                        label="Alguma habilidade específica?"
                                        multiline
                                        rows={4}
                                        value={updateInputValue(user, values, 'habilidade_especifica')}
                                        sx={{ background: '#ffffff'}}
                                        disabled={isDisabled}
                                    />
                                </Grid>

                            </Grid>
                            
                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0',
                                            width: '100%'
                                        }
                                    }
                            >

                                {userLanguages.map((x, i) => (
                                    small.matches 
                                    ? 
                                    (
                                        <>
                                        <Grid item xs={6}>

                                            <Grid item xs={12} sx={{marginBottom: {xs: '0.5rem'}}}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="idioma">Idiomas</InputLabel>
                                                    <JumboSelectField
                                                        labelId="idiomas_id"
                                                        id="idiomas_id"
                                                        name="idiomas_id"
                                                        label="Idiomas"
                                                        value={x.idiomas_id}
                                                        onChange={e => handleInputChange(e, i)}
                                                        sx={{ background: '#ffffff'}}
                                                        disabled={isDisabled}
                                                    >
                                                    <MenuItem value={1} style={{ display: userLanguages.some(item => item.idiomas_id == 1) ? 'none' : 'block' }}>Alemão</MenuItem>
                                                    <MenuItem value={2} style={{ display: userLanguages.some(item => item.idiomas_id == 2) ? 'none' : 'block' }}>Espanhol</MenuItem>
                                                    <MenuItem value={3} style={{ display: userLanguages.some(item => item.idiomas_id == 3) ? 'none' : 'block' }}>Francês</MenuItem>
                                                    <MenuItem value={4} style={{ display: userLanguages.some(item => item.idiomas_id == 4) ? 'none' : 'block' }}>Inglês</MenuItem>
                                                    <MenuItem value={5} style={{ display: userLanguages.some(item => item.idiomas_id == 5) ? 'none' : 'block' }}>Italiano</MenuItem>
                                                    <MenuItem value={6} style={{ display: userLanguages.some(item => item.idiomas_id == 6) ? 'none' : 'block' }}>Outro</MenuItem>
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="nivel_idioma">Nível</InputLabel>
                                                    <JumboSelectField
                                                        labelId="nivel_idioma"
                                                        id="nivel_idioma"
                                                        name="nivel_idioma"
                                                        label="Nível"
                                                        value={x.nivel_idioma}
                                                        onChange={e => handleInputChange(e, i)}
                                                        sx={{ background: '#ffffff'}}
                                                        disabled={isDisabled}
                                                    >
                                                        <MenuItem value={'basico'}>Básico</MenuItem>
                                                        <MenuItem value={'intermediario'}>Intermediário</MenuItem>
                                                        <MenuItem value={'avancado'}>Avançado</MenuItem>
                                                        <MenuItem value={'fluente'}>Fluente</MenuItem>
                                                    
                                                    </JumboSelectField>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                            
                                            <Grid item xs={12}>
                                                {
                                                x.idiomas_id == '' || x.nivel_idioma == '' 
                                                ? 
                                                    null 
                                                : 
                                                userLanguages.length !== 1 &&
                                                    
                                                    <Button
                                                        color="error"
                                                        variant="contained"
                                                        size="large"
                                                        component="label"
                                                        onClick={() => handleRemoveClick(i)}
                                                        sx={{height:'53.13px', fontSize: {xs: '0.7rem'}}}
                                                        disabled={isDisabled}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                
                                                }
                                            {userLanguages.length - 1 === i && 
                                                <Button
                                                    sx={{height:'53.13px', fontSize: {xs: '0.6rem'}, padding: {lg: '8px 22px', xs: '8px 5px'}}}
                                                    color="info"
                                                    variant="contained"
                                                    size="large" 
                                                    component="label"                 disabled= {x.idiomas_id == '' || x.nivel_idioma == '' || isDisabled} 
                                                    onClick={handleAddClick}
                                                >
                                                    Adicionar outro idioma
                                                </Button>
                                            }
                                            </Grid>

                                        </Grid>
                                        </>
                                    )
                                    : 
                                    (
                                        <>
                                    
                                        <Grid item xs={4} sx={{marginBottom: {xs: '0.5rem'}}}>
                                            <FormControl fullWidth>
                                                <InputLabel id="idioma">Idiomas</InputLabel>
                                                <JumboSelectField
                                                    labelId="idiomas_id"
                                                    id="idiomas_id"
                                                    name="idiomas_id"
                                                    label="Idiomas"
                                                    value={x.idiomas_id}
                                                    onChange={e => handleInputChange(e, i)}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                >
                                                    <MenuItem value={1} style={{ display: userLanguages.some(item => item.idiomas_id == 1) ? 'none' : 'block' }}>Alemão</MenuItem>
                                                    <MenuItem value={2} style={{ display: userLanguages.some(item => item.idiomas_id == 2) ? 'none' : 'block' }}>Espanhol</MenuItem>
                                                    <MenuItem value={3} style={{ display: userLanguages.some(item => item.idiomas_id == 3) ? 'none' : 'block' }}>Francês</MenuItem>
                                                    <MenuItem value={4} style={{ display: userLanguages.some(item => item.idiomas_id == 4) ? 'none' : 'block' }}>Inglês</MenuItem>
                                                    <MenuItem value={5} style={{ display: userLanguages.some(item => item.idiomas_id == 5) ? 'none' : 'block' }}>Italiano</MenuItem>
                                                    <MenuItem value={6} style={{ display: userLanguages.some(item => item.idiomas_id == 6) ? 'none' : 'block' }}>Outro</MenuItem>
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="nivel_idioma">Nível</InputLabel>
                                                <JumboSelectField
                                                    labelId="nivel_idioma"
                                                    id="nivel_idioma"
                                                    name="nivel_idioma"
                                                    label="Nível"
                                                    value={x.nivel_idioma}
                                                    onChange={e => handleInputChange(e, i)}
                                                    sx={{ background: '#ffffff'}}
                                                    disabled={isDisabled}
                                                >
                                                    <MenuItem value={'basico'}>Básico</MenuItem>
                                                    <MenuItem value={'intermediario'}>Intermediário</MenuItem>
                                                    <MenuItem value={'avancado'}>Avançado</MenuItem>
                                                    <MenuItem value={'fluente'}>Fluente</MenuItem>
                                                
                                                </JumboSelectField>
                                            </FormControl>
                                        </Grid>
                                
                                        <Grid item xs={4}>
                                            {x.idiomas_id == '' || x.nivel_idioma == '' ? null
                                            :
                                            userLanguages.length !== 1 && 
                                            
                                                <Button
                                                    color="error"
                                                    variant="contained"
                                                    size="large"
                                                    component="label"
                                                    onClick={() => handleRemoveClick(i)}
                                                    sx={{height:'60px', 
                                                        borderRadius:'50%', 
                                                        marginRight: {xs: '0.5rem'},
                                                        fontSize: 
                                                        {
                                                            xs: '0.7rem'
                                                        }
                                                    }}
                                                    disabled={isDisabled}
                                                >
                                                    <DeleteIcon />
                                                </Button>

                                            }
                                        {userLanguages.length - 1 === i && 
                                            <Button
                                                sx={{height:'53.13px',fontSize: {xs: '0.7rem'}, padding: {lg: '8px 22px', xs: '8px 5px'}}}
                                                color="info"
                                                variant="contained"
                                                size="large" 
                                                component="label"
                                                disabled= {x.idiomas_id == '' || x.nivel_idioma == ''  || isDisabled} 
                                                onClick={handleAddClick}
                                                
                                            >
                                                Adicionar outro idioma
                                            </Button>
                                        }
                                        </Grid>

                                        </> 
                                    )
                                    
                                ))}
                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item xs={12}>
                                    <Accordion 
                                        expanded={!disableInputValue(user, values, 'quais_cuidados')}
                                        elevation={0}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                                <Stack
                                                    sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                    direction={{ xs: 'column', lg: 'row' }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography
                                                        sx={{fontSize: '1.05rem'}}
                                                    >
                                                        Algum cuidado relacionado à sua saúde que gostaria que soubéssemos?
                                                    </Typography>

                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Typography>Não</Typography>
                                                            <FormControlLabel
                                                                control={
                                                                <Switch 
                                                                    checked={updateInputValue(user, values, 'quais_cuidados')}
                                                                    onChange={handleChange}
                                                                    name="quais_cuidados" 
                                                                    disabled={isDisabled}
                                                                />
                                                                }
                                                                labelPlacement="start"
                                                            />
                                                        <Typography>Sim</Typography>
                                                    </Stack>

                                                </Stack>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid item xs={12}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="obs_saude"
                                                        label="Quais?"
                                                        multiline
                                                        rows={4}
                                                        value={updateInputValue(user, values, 'obs_saude')}
                                                        disabled={isDisabled ?? disableInputValue(user, values, 'quais_cuidados')}
                                                        
                                                    />
                                                </Grid>
                                            </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Typography variant='h5'>
                                        Pessoa de contato para casos de emergência
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="nome_emergencia"
                                        label="Nome"
                                        sx={{ background: '#ffffff'}}
                                        value={updateInputValue(user, values, 'nome_emergencia')}
                                        disabled={isDisabled}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <InputMask
                                        mask="+999(99)99999-9999"
                                        value={updateInputValue(user, values, 'celular_emergencia')}
                                        onChange={handleChange}
                                        disabled={isDisabled}
                                        disabledUnderline={isDisabled}
                                    >
                                        {(inputProps) => 
                                            <JumboTextField
                                                {...inputProps}
                                                fullWidth
                                                name="celular_emergencia"
                                                label="Celular (DDI, DDD e número)"
                                                disabled={inputProps.disabledUnderline}
                                                sx={{ background: '#ffffff'}}
                                            />
                                        }
                                        
                                    </InputMask>
                                </Grid>

                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item lg={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="religiao">Religião de origem</InputLabel>
                                        <JumboSelectField
                                            labelId="religiao"
                                            id="religiao"
                                            name="religiao"
                                            label="Religião de origem"
                                            value={updateInputValue(user, values, 'religiao')}
                                            sx={{ background: '#ffffff'}}
                                            disabled={isDisabled}
                                        >
                                            <MenuItem value={'budismo'}>Budismo</MenuItem>
                                            <MenuItem value={'catolica'}>Católica</MenuItem>
                                            <MenuItem value={'espiritismo'}>Espiritismo</MenuItem>
                                            <MenuItem value={'matriz_africana'}>Matriz africana</MenuItem>
                                            <MenuItem value={'protestante'}>Protestante</MenuItem>
                                            <MenuItem value={'umbanda'}>Umbanda</MenuItem>
                                            <MenuItem value={'outra'}>Outra</MenuItem>
                                        
                                        </JumboSelectField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={!disableInputValue(user, values, 'conhece_espiristimo')}
                                        elevation={0}
                                    >
                                        <AccordionSummary>
                                            <Stack
                                                sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                direction={{ xs: 'column', lg: 'row' }}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography
                                                    sx={{fontSize: '1.05rem'}}
                                                >
                                                    Conhece o Espiritismo?
                                                </Typography>
                                                
                                                <Stack 
                                                    direction="row" 
                                                    spacing={1} 
                                                    alignItems="center"
                                                >
                                                    <Typography>Não</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={updateInputValue(user, values, 'conhece_espiristimo')}
                                                                    onChange={handleChange}
                                                                    name="conhece_espiristimo" 
                                                                    disabled={isDisabled}
                                                                />
                                                            }
                                                            labelPlacement="start"
                                                        />
                                                    <Typography>Sim</Typography>
                                                </Stack>
                                            </Stack>

                                        </AccordionSummary>                        
                                        <AccordionDetails>
                                            <Grid item xs={12}>
                                                <JumboTextField
                                                    fullWidth
                                                    name="cursos_fora_feesp"
                                                    label="Descreva basicamente sua experiência no Espiritismo: Outros centros espíritas que frequentou, cursos que realizou e em que época."
                                                    multiline
                                                    rows={4}
                                                    value={updateInputValue(user, values, 'cursos_fora_feesp')}
                                                    disabled={isDisabled ?? disableInputValue(user, values, 'conhece_espiristimo')}
                                                />
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignContent={'center'} 
                                sx={
                                        {   mt: '0.8rem',
                                            background: '#EFF0F2',
                                            marginLeft: 0,
                                            padding: '0 24px 24px 0'
                                        }
                                    }
                            >
                                <Grid item xs={12}>
                                    <Accordion
                                        expanded={!disableInputValue(user, values, 'trabalhos_voluntarios')}
                                        elevation={0}
                                    >
                                        <AccordionSummary>
                                            <Stack
                                                sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                direction={{ xs: 'column', lg: 'row' }}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography
                                                    sx={{fontSize: '1.05rem'}}
                                                >
                                                    Realiza ou realizou trabalho voluntário?
                                                </Typography>

                                                <Stack 
                                                    direction="row" 
                                                    spacing={1} 
                                                    alignItems="center"
                                                >
                                                    <Typography>Não</Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch 
                                                                    checked={updateInputValue(user, values, 'trabalhos_voluntarios')}
                                                                    onChange={handleChange}
                                                                    name="trabalhos_voluntarios" 
                                                                    disabled={isDisabled}
                                                                />
                                                            }
                                                            labelPlacement="start"
                                                        />
                                                    <Typography>Sim</Typography>
                                                </Stack>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Grid item xs={12}>
                                            <JumboTextField
                                                fullWidth
                                                name="obs_voluntariado"
                                                label="Descreva basicamente os três últimos informando também o nome da entidade ou o local de atuação;
                                                "
                                                multiline
                                                rows={4}
                                                value={updateInputValue(user, values, 'obs_voluntariado')}
                                                disabled={isDisabled ?? disableInputValue(user, values, 'trabalhos_voluntarios')}
                                            />
                                        </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                            

                            {id ? 
                                (
                                    <>

                                        <Grid container spacing={3} alignContent={'center'} 
                                        sx={
                                                {   mt: '0.8rem',
                                                    background: '#EFF0F2',
                                                    marginLeft: 0,
                                                    padding: '0 24px 24px 0'
                                                }
                                            }
                                        >

                                            <Grid item xs={12} sx={{mt: '1rem'}}>
                                                <Stack 
                                                    direction="row" 
                                                    spacing={2} 
                                                    alignItems="center"
                                                >
                                                    <Typography sx={{fontSize: '1.05rem'}}>
                                                        Histórico de cursos
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sx={{textAlign: 'center', mb: '1rem'}}> 
                                                <LoadingButton
                                                    color="info"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{maxWidth: {md: '400px', mt: '1rem'}}}
                                                    loading={isSubmitting}
                                                    onClick={handleOpen}
                                                >
                                                    {'Acessar Histórico'}
                                                </LoadingButton>
                                            </Grid>
                                            
                                        </Grid>

                                        <Grid container spacing={3} alignContent={'center'} 
                                        sx={
                                                {   mt: '0.8rem',
                                                    background: '#EFF0F2',
                                                    marginLeft: 0,
                                                    padding: '0 24px 24px 0'
                                                }
                                            }
                                        >

                                            <Grid item xs={12} sx={{mt: '1rem'}}>
                                                <Stack 
                                                    direction="row" 
                                                    spacing={2} 
                                                    alignItems="center"
                                                >
                                                    <Typography sx={{fontSize: '1.05rem'}}>
                                                        Histórico de voluntariado
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sx={{textAlign: 'center', mb: '1rem'}}> 
                                                <LoadingButton
                                                    color="info"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{maxWidth: {md: '400px', mt: '1rem'}}}
                                                    loading={isSubmitting}
                                                    onClick={handleOpenVolunteer}
                                                >
                                                    Acessar Histórico
                                                </LoadingButton>
                                            </Grid>
                                            
                                        </Grid>
                                        <Grid container spacing={3} alignContent={'center'} 
                                            sx={
                                                    {   mt: '0.8rem',
                                                        background: '#EFF0F2',
                                                        marginLeft: 0,
                                                        padding: '0 24px 24px 0'
                                                    }
                                                }
                                        >
                                            <Grid item xs={12}>
                                               { console.log(user.infos_usuario, values.infos_usuario)}
                                                <Accordion
                                                    expanded={updateInputValue(user, values, 'infos_usuario') == 'on' ? true : false}
                                                    elevation={0}
                                                >
                                                    <AccordionSummary>
                                                        <Stack
                                                            sx={{width: '100%', textAlign: {xs: 'center', lg: 'left'}}}
                                                            direction={{ xs: 'column', lg: 'row' }}
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography
                                                                sx={{fontSize: '1.05rem'}}
                                                            >
                                                                Informações sobre criação e atualização do usuário
                                                            </Typography>

                                                            <Stack 
                                                                direction="row" 
                                                                spacing={1} 
                                                                alignItems="center"
                                                            >
                                                                <Typography>Ocultar</Typography>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch 
                                                                                checked={updateInputValue(user, values, 'infos_usuario') == 'on' ? true : false}
                                                                                onChange={handleChange}
                                                                                name="infos_usuario" 
                                                                                disabled={isDisabled}
                                                                            />
                                                                        }
                                                                        labelPlacement="start"
                                                                    />
                                                                <Typography>Visualizar</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                    <Grid item xs={12}>
                                                        <InfosAboutCreateAndUpdate 
                                                            data={user} 
                                                            data_quem_criou={whoCreated}
                                                            data_quem_atualizou={whoUpdated}
                                                            paperBg={'#EFF0F2'}
                                                        /> 
                                                    </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    </> 
                                )
                            : 
                                null
                            }                                
                            

                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={open}>
                                    <Div sx={style}>
                                        <ModalCourseHistory handleClose={handleClose}/>
                                    </Div>
                                </Fade>
                            </Modal>

                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={openVolunteer}
                                onClose={handleCloseVolunteer}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={openVolunteer}>
                                    <Div sx={style}>
                                        <ModalVolunteerInterestedHistory handleCloseVolunteer={handleCloseVolunteer}/>
                                    </Div>
                                </Fade>
                            </Modal>

                            <Grid item xs={12} sx={{textAlign: 'center', mt: '1rem'}}> 
                                <LoadingButton
                                    color="success"
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{maxWidth: {md: '200px', mt: '1rem'}}}
                                    loading={isSubmitting}
                                    disabled={isDisabled}
                                >
                                    {id ? 'Atualizar' : 'Cadastrar'}
                                </LoadingButton>

                            </Grid>
                        </Grid>

                        {  isSubmitting /* && Object.keys(errors).length !== 0 */
                        ? errorsHandler(errors) 
                        : null
                        }
                        {
                            errorsFromApi.length > 0 
                            ? window.scrollTo({ top: 0, behavior: 'smooth' })
                            : null
                        }
                    </Form>
                    
                    )}
            </Formik>
        </Paper>
    </JumboContentLayout>
  )
}

export default UserForm