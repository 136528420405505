import React, { useState, useCallback, useEffect, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Box, CircularProgress, Grid, Paper, Stack, Tooltip, Typography, useMediaQuery, Zoom} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { ApiService } from 'app/servicesTwo/ApiService';
import UserItem from "./UserItem";
import { Link } from "react-router-dom";
import Div from '@jumbo/shared/Div';
import JumboSearch from '@jumbo/components/JumboSearch';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVStyled, Item } from '../classes/ClassForm';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const validationSchema = yup.object({
    email: yup
        .string('Insira seu email')
        .email('Insira um email válido'),
    senha: yup
        .string('Insira sua senha'),
    nome: yup
        .string('Insira seu nome')
        .required('Preenchimento obrigatório'),
    cpf: yup
        .string(),
    rg: yup
        .string()
        .max(11, 'Deve ter no máximo 11 digitos'),
    data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório'),
    celular: yup
        .string()
});

const UserList = () => {  
    const {theme} = useJumboTheme();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [users, setUsers] = useState(null)
    const { hasPermission } = useContext(PermissionContext);

    const getUsers = useCallback(async () => {
        setLoading(true);
        try {
            ApiService.get('/pessoa')
            .then((response) => {
              setUsers(response.data);
              setLoading(false);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
                setLoading(false);
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      useEffect(() => {
        getUsers();
      }, []);

      useEffect(() => {
        console.log('atualizou')
      }, [setUsers, users]);

      const [searchTerm, setSearchTerm] = useState(null)
      const [loading, setLoading] = useState(false)
  
      useEffect(() => {
          if(searchTerm == undefined) return;
  
          if(searchTerm == '') {
              setLoading(true)
              searchUser(searchTerm)
              setLoading(false)
          }
  
          const delayDebounceFn = setTimeout(() => {
            setLoading(true)
            searchUser(searchTerm)
            setLoading(false)
          }, 1500)
  
          
          return () => clearTimeout(delayDebounceFn)
        }, [searchTerm])

        const searchUser = useCallback(async (e) => {

            try {
                ApiService.get('/pessoa', {params: {search: e}})
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => {
                  if (error.response) {
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      console.log(error.request);
                    } else {
                      console.log('error', error.message);
                    }
                })
            } catch (err) {
              console.log(err);
            }
        }, []);

        const data = [];

        if(users) {
            for (const item of users) {
                const cursos = item.Curso_Historico || [];
                const idiomas = item.idioma || [];

                const cleanClasses = {
                    nome: item.nome,
                    cpf: item.cpf,
                    email: item.email,
                    idioma: item.idioma,
                    nivel_idioma: item.nivel_idioma ,
                    data_nascimento: item.data_nascimento,
                    genero: item.genero,
                    nacionalidade: item.nacionalidade,
                    rg: item.rg,
                    telefone: item.telefone,
                    celular: item.celular,
                    aceita_contato_whatsapp: item.aceita_contato_whatsapp,
                    documento_internacional: item.documento_internacional,
                    estado_civil: item.estado_civil,
                    tem_filhos: item.tem_filhos,
                    qtde_filhos: item.qtde_filhos,
                    quais_cuidados: item.quais_cuidados,
                    escolaridade: item.escolaridade,
                    formacao_principal: item.formacao_principal,
                    ocupacao: item.ocupacao,
                    ocupacao_natureza: item.ocupacao_natureza ,
                    profissao: item.profissao,
                    habilidade_especifica: item.habilidade_especifica,
                    religiao: item.religiao,
                    conhece_espiristimo: item.conhece_espiristimo,
                    cursos_fora_feesp: item.cursos_fora_feesp,
                    obs_voluntariado: item.obs_voluntariado,
                    obs_gerais: item.obs_gerais,
                    bairro: item.bairro,
                    cidade: item.cidade,
                    estado: item.estado,
                    CEP: item.CEP,
                    codigo_postal: item.codigo_postal,
                    complemento: item.complemento,
                    endereco: item.endereco,
                    numero: item.numer,
                    pais: item.pais,
                    nome_emergencia: item.nome_emergencia ,
                    celular_emergencia: item.celular_emergencia,
                }
    
                if (cursos.length === 0 || idiomas.length === 0) {
                    const newRow = {
                        ...cleanClasses,
                        ano: "",
                        nome_do_curso: "",
                        dia_da_semana:"",
                        horario: "",
                        local: "",
                        status: "",
                        motivo_reprovacao: "",
                        idioma: "",
                        nivel_idioma: ""
                    };
                    data.push(newRow);
                } else {
                    const idToLabel = {
                        1: "Alemão",
                        2: "Espanhol",
                        3: "Francês",
                        4: "Inglês",
                        5: "Italiano",
                        6: "Outro"
                    };
                    for (const curso of cursos) {
                        for (const idioma of idiomas) {
                            const newRow = {
                                ...cleanClasses,
                                ano: curso.Turma?.ano || "",
                                nome_do_curso: curso.Turma.Curso.nome || "",
                                dia_da_semana: curso.Turma?.dia_da_semana || "",
                                horario: curso.Turma?.horario || "",
                                local: curso.Turma?.local || "",
                                status: curso.status || "",
                                motivo_reprovacao: curso.motivo_reprovacao || "",
                                idioma: idToLabel[idioma.idiomas_id] || "",
                                nivel_idioma: idioma.nivel_idioma.toString()
                            };
                            data.push(newRow);
                        }
                    }
                }
            }
        }

        const generateOptions = (key) => ({ label: key, key });
    
        const headers = [
            
            // para alunos
            generateOptions('nome'),
            generateOptions('cpf'),
            generateOptions('email'),
            generateOptions('idioma'),
            generateOptions('nivel_idioma'),
            generateOptions('data_nascimento'),
            generateOptions('genero'),
            generateOptions('nacionalidade'),
            generateOptions('rg'),
            generateOptions('telefone'),
            generateOptions('celular'),
            generateOptions('aceita_contato_whatsapp'),
            generateOptions('documento_internacional'),
            generateOptions('estado_civil'),
            generateOptions('tem_filhos'),
            generateOptions('qtde_filhos'),
            generateOptions('quais_cuidados'),
            generateOptions('escolaridade'),
            generateOptions('formacao_principal'),
            generateOptions('ocupacao'),
            generateOptions('ocupacao_natureza'),
            generateOptions('profissao'),
            generateOptions('habilidade_especifica'),
            generateOptions('religiao'),
            generateOptions('conhece_espiristimo'),
            generateOptions('cursos_fora_feesp'),
            generateOptions('obs_voluntariado'),
            generateOptions('obs_gerais'),
            generateOptions('bairro'),
            generateOptions('cidade'),
            generateOptions('estado'),
            generateOptions('CEP'),
            generateOptions('codigo_postal'),
            generateOptions('complemento'),
            generateOptions('endereco'),
            generateOptions('numero'),
            generateOptions('pais'),
            generateOptions('nome_emergencia'),
            generateOptions('celular_emergencia'),


            // para historico_cursos
            generateOptions('ano'),
            generateOptions('nome_do_curso'),
            generateOptions('dia_da_semana'),
            generateOptions('horario'),
            generateOptions('local'),
            generateOptions('status'),
            generateOptions('motivo_reprovacao'),
        ];

    const headerLabels = headers.map(header => header.label);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(data, { header: headerLabels });
        const wb = { Sheets: { 'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const nData = new Blob([excelBuffer], { type: fileType})
        FileSaver.saveAs(nData, 'pessoas' + fileExtension)
    }

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Pessoas"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            
            <Grid item xs={1} sx={{textAlign: 'center'}}> 
                <Link to="/app/novo-aluno" style={{ textDecoration:'none', pointerEvents: !hasPermission('Pessoas', 'criar') ? 'none' : ''  }}>
                    <LoadingButton
                        color="success"
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={!hasPermission('Pessoas', 'criar') ? true : false}
                        sx={{maxWidth: {md: '200px', mt: '1rem'}, marginBottom: '2rem'}}
                    >
                        Novo Cadastro
                    </LoadingButton>
                </Link>
            </Grid>

            {/* <Grid item xs={1} sx={{textAlign: 'center'}}> 
                <JumboSearch
                    onChange={(e) => setSearchTerm(e)}
                    placeholder="Busque por nome, email, celular e cpf."
                    sx={{
                        width: '440px',
                        marginBottom: '1rem'
                    }}
                />
                
            </Grid>    */}
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                
                <JumboSearch
                    onChange={(e) => setSearchTerm(e)}
                    placeholder="Busque por nome, email, celular e cpf."
                    sx={{
                        width: '440px',
                        marginBottom: '1rem'
                    }}
                />
                
                <Tooltip
                    title="Exportar dados"
                    TransitionComponent={Zoom}
                    arrow
                >
                    
                    <CSVStyled onClick={(e) => exportToExcel()}>
                        <Typography
                            variant={"h5"}
                            color="#ffffff"
                            mb={.25}
                        >
                            Download
                        </Typography>
                        <DownloadIcon/>
                    </CSVStyled>
                    
                </Tooltip>

            </Div>
            <>
                {loading 
                    && 
                    <Box display='flex' justifyContent='center'>
                        <CircularProgress  /> 
                    </Box>
                }
                {
                    (users != null && users.length == 0) && <Typography>Sem resultados correspondentes</Typography>
                }

                {
                    users && users.map((user, key) => (
                        <UserItem item={user} setUsers={(data) => setUsers([...data])} users={users} key={key}/>
                    ))
                }

            </>
        </Paper>
    </JumboContentLayout>
  )
}

export default UserList