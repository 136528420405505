import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Zoom} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import capitalize from "../../../utils/capitalize";
import formatDate from "../../../utils/formatDate";
import { Link } from 'react-router-dom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';


const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const LinkStyle = styled(Link)`
    padding: 4px 10px;
    background: #7352C7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    
    svg {
        color: white;
    }
`

const CourseItem = ({ item ,setCourses, courses }) => {
    const { hasPermission } = useContext(PermissionContext);

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
        });
    };

    const modalAlert = (id) => {
        if(item.turmasComEsteCurso > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html:`<strong style="color:red">Não é possível excluir o curso porque há ${item.turmasComEsteCurso} turmas associadas.` ,
                
            })
        } else {
            Swal.fire({
                title: 'Tem certeza que deseja apagar?',
                text: 'Não será póssível reverter a ação!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    deleteCourse(id);
                }
            });
        }
    };

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
      }

    const deleteCourse = useCallback(async (id) => {
        try {
            ApiService.delete(`/cursos/${id}`)
            .then((response) => {
                toast('success', 'Removido com sucesso');
                const updatedCoursesArray = removeById(courses, id)
                setCourses(updatedCoursesArray);
            })
            .catch((error) => {
              if (error.response) {
                toast('error', error.response.data.error);
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
          toast('error', 'Ocorreu um erro');
        }
        
    }, []);

    const maxLengthText = 20;

    return (
        <Card sx={{mb: 1}}>
            <Accordion square sx={{borderRadius: 2}}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{flexShrink: 0, px: 1, display: 'none'}}>
                        <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={item.imagem_curso ? `${process.env.REACT_APP_API_KEY}/images/${item.imagem_curso}` : '#'} variant="rounded"/>
                    </Div>
                    {/* <Div sx={{display: {xs: 'block',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width:'20%', lineBreak: 'anywhere', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Código
                        </Typography>
                        <Typography variant={"body1"}>{item.curso_id}</Typography>
                    </Div> */}
                    <Div sx={{display: {xs: 'block',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width: { xs: '40%', xl: '30%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Curso
                        </Typography>
                        <Typography variant={"body1"}>{item.nome}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width: { xs: '30%', xl: '30%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Departamento
                        </Typography>
                        <Typography variant={"body1"}>{item.Departamento.nome}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none',sm: 'block', md: 'block', lg: 'none', lg: 'block'}, width: { xs: '30%', xl: '30%'}, lineBreak: 'anywhere', flexShrink: 1, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Área
                        </Typography>
                        <Typography variant={"body1"}>{item.Departamento.Areas.nome}</Typography>
                    </Div>
                    <Div sx={{ ml: 'auto', flexDirection: 'row', display:'flex' }}>
                        <Item sx={{ml: 'auto', display: {sm: 'block'}}}>
                            <LinkStyle to={`/app/editar-curso/${item.curso_id}`}>
                                <VisibilityIcon />
                            </LinkStyle>
                        </Item>

                        <Item sx={{ml: 'auto', display: {sm: 'block'}}}>
                            <Button 
                                sx={{minWidth: 1, background: item.turmasComEsteCurso > 0 ? 'rgba(0, 0, 0, 0.12)' : ''}} 
                                disableElevation 
                                variant={"contained"} 
                                size={"small"} 
                                color={"secondary"}
                                disabled={!hasPermission('Cursos', 'deletar') ? true : false}
                                onClick={() => modalAlert(item.curso_id)}
                            >
                                <DeleteIcon />
                            </Button>
                        </Item>  
                    </Div>
                </AccordionSummary>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */




export default CourseItem;
