import React from "react";
import Home from "../pages/home";
import Login from "../pages/login";
import ContactsApp from '../pages/app/contacts/ContactsApp'
import Page from "@jumbo/shared/Page";
import UserForm from "../pages/app/users/UserForm";
import UserList from "../pages/app/users/UserList";
import ClassList from "../pages/app/classes/ClassList";
import ClassForm from "../pages/app/classes/ClassForm";
import CourseForm from "../pages/app/courses/CourseForm";
import CourseList from "../pages/app/courses/CourseList";
import ModalCourseHistory from "../pages/app/course_histories/ModalCourseHistory";
import DepartmentList from "../pages/app/departments/DepartmentList";
import DepartmentForm from "../pages/app/departments/DepartmentForm";
import AreaList from "app/pages/app/areas/AreaList";
import AreaForm from "app/pages/app/areas/AreaForm";
import LogList from "../pages/app/logs/LogList";
import ProfileList from "app/pages/app/profiles/ProfileList";
import ProfileForm from "app/pages/app/profiles/ProfileForm";
import ProtectedRoute from "app/routes/ProtectedRoute";
import UserRegistrationForm from "../pages/app/registerUsers/UserRegistrationForm";
import UserRegistrationList from "../pages/app/registerUsers/UserRegistrationList";
import ForgotPassword from "app/pages/forgotPassword/ForgotPassword";
import ResetPassword from "app/pages/resetPassword/ResetPassword";
import VolunteerInterestedList from "app/pages/app/volunteersInterested/VolunteerInterestedList";
import VolunteerInterestedFormResume from "app/pages/app/volunteersInterested/VolunteerInterestedFormResume";
import VolunteerList from "app/pages/app/volunteers/VolunteerList";
import VolunteerFormResume from "app/pages/app/volunteers/VolunteerFormResume";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/esqueci-senha",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },

    {
        path: "/reset-senha",
        element: <Page component={ResetPassword} layout={"solo-page"} />
    },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Home} />,
    },
    {
        path: "/app/contatos",
        element: <Page component={ContactsApp} />
    },
    {
        path: "/app/listar-usuarios",
        element: 
        <ProtectedRoute 
            permission={'Pessoas'} 
            requiredRole={'acessar'}
            component={UserList}
        />
    },
    {
        path: "/app/novo-aluno",
        element:
        <ProtectedRoute 
            permission={'Pessoas'} 
            requiredRole={'criar'}
            component={UserForm}
        />
    },
    {
        path: "/app/editar-aluno/:id",
        element:
        <ProtectedRoute 
            permission={'Pessoas'} 
            requiredRole={'acessar'}
            component={UserForm}
        />
    },
    
    {
        path: "/app/listar-areas",
        element:
        <ProtectedRoute 
            permission={'Áreas'} 
            requiredRole={'acessar'}
            component={AreaList}
        />
    },
    {
        path: "/app/nova-area",
        element:
        <ProtectedRoute 
            permission={'Áreas'} 
            requiredRole={'criar'}
            component={AreaForm}
        />
    },
    {
        path: "/app/editar-area/:id",
        element:
        <ProtectedRoute 
            permission={'Áreas'} 
            requiredRole={'acessar'}
            component={AreaForm}
        />
    },


    {
        path: "/app/listar-cursos",
        element: 
        <ProtectedRoute 
            permission={'Cursos'} 
            requiredRole={'acessar'}
            component={CourseList}
        />
    },
    {
        path: "/app/novo-curso",
        element:
        <ProtectedRoute 
            permission={'Cursos'} 
            requiredRole={'criar'}
            component={CourseForm}
        />
    },
    {
        path: "/app/editar-curso/:id",
        element:
        <ProtectedRoute 
            permission={'Cursos'} 
            requiredRole={'acessar'}
            component={CourseForm}
        />
    },
    {
        path: "/app/historico-cursos",
        element: <Page component={ModalCourseHistory} />
    },
    {
        path: "/app/listar-turmas",
        element:
        <ProtectedRoute 
            permission={'Turmas'} 
            requiredRole={'acessar'}
            component={ClassList}
        />
    },
    {
        path: "/app/nova-turma",
        element: 
        <ProtectedRoute 
            permission={'Turmas'} 
            requiredRole={'criar'}
            component={ClassForm}
        />
    },
    {
        path: "/app/editar-turma/:id",
        element: 
        <ProtectedRoute 
            permission={'Turmas'} 
            requiredRole={'acessar'}
            component={ClassForm}
        />
    },
    {
        path: "/app/listar-departamentos",
        element:
        <ProtectedRoute 
            permission={'Departamentos'}  
            requiredRole={'acessar'}
            component={DepartmentList}
        />
    },
    {
        path: "/app/novo-departamento",
        element:
        <ProtectedRoute 
            permission={'Departamentos'}  
            requiredRole={'criar'}
            component={DepartmentForm}
        />
    },
    {
        path: "/app/editar-departamento/:id",
        element:
        <ProtectedRoute 
            permission={'Departamentos'}  
            requiredRole={'acessar'}
            component={DepartmentForm}
        />
    },
    {
        path: "/app/log-de-atividades",
        element:
        <ProtectedRoute 
            permission={'Log de Atividades'}  
            requiredRole={'acessar'}
            component={LogList}
        />
    },
    {
        path: "/app/listar-perfis-de-acesso",
        element:
        <ProtectedRoute 
            permission={'Perfis'}  
            requiredRole={'acessar'}
            component={ProfileList}
        />
    },
    {
        path: "/app/novo-perfil",
        element:
        <ProtectedRoute 
            permission={'Perfis'}  
            requiredRole={'criar'}
            component={ProfileForm}
        />
    },
    {
        path: "/app/editar-perfil/:id",
        element: 
        <ProtectedRoute 
            permission={'Perfis'}  
            requiredRole={'acessar'}
            component={ProfileForm}
        />
    },

    {
        path: "/app/listar-usuarios-registrados",
        element: 
        <ProtectedRoute 
            permission={'Usuários'} 
            requiredRole={'acessar'}
            component={UserRegistrationList}
        />
    },
    {
        path: "/app/novo-usuario",
        element:
        <ProtectedRoute 
            permission={'Usuários'} 
            requiredRole={'criar'}
            component={UserRegistrationForm}
        />
    },
    {
        path: "/app/editar-usuario/:id",
        element:
        <ProtectedRoute 
            permission={'Usuários'} 
            requiredRole={'acessar'}
            component={UserRegistrationForm}
        />
    },
    {
        path: "/app/listar-voluntariado-interesse-resume/:id",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerInterestedFormResume}
        />

    },
    {
        path: "/app/novo-voluntariado-interesse",
        element: 
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'criar'}
            component={VolunteerInterestedFormResume}
        />
    },
    
    {
        path: "/app/listar-voluntariado-interesse",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerInterestedList}
        />
    },
    {
        path: "/app/listar-voluntariado",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerList}
        />
    },
    {
        path: "/app/novo-voluntariado",
        element: 
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'criar'}
            component={VolunteerFormResume}
        />
    },
    {
        path: "/app/listar-voluntariado-resume/:id",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerFormResume}
        />

    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};